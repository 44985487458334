import { Box, Card, CardHeader, Divider, Grid, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';
import { useCallback } from 'react';
import { TcsVignette } from './tcs-vignette';

export const TcsDetails = (props) => {
  const { tcsDetails } = props;
  const [isOpenList, setIsOpenList] = useState(new Array(tcsDetails.length).fill(false));

  if (!tcsDetails || tcsDetails.length === 0) {
    return null;
  }

  const handleItemClick = useCallback((index) => {
    const updatedIsOpenList = [...isOpenList];
    updatedIsOpenList[index] = !updatedIsOpenList[index];
    setIsOpenList(updatedIsOpenList);
  }, [isOpenList]);

  return (
    <Box sx={{mb: 15}}>
      {tcsDetails.map((item, index) => (
        <Card
        key={index}
          sx={{
            mx:10,
            mt: 4,
            p:3
          }}
        >
          <Grid container
            onClick={() => handleItemClick(index)}
          >
            <Grid item
              sx={{
                mr: "1%"
              }}
            >
              {isOpenList[index] ?
              <KeyboardArrowDownIcon
              fontSize='large'
              />
              :
              <KeyboardArrowRightIcon
              fontSize='large'
              />
              }
            </Grid>
            <Grid item
            >
              <Typography
                variant='h5'
                >
                {"Mini Vignette " + item.number}
              </Typography>
            </Grid>
          </Grid>
          {isOpenList[index] ?
            <Grid>
              <Typography
                color="secondary"
                fontWeight='Bold'
                fontSize={16}
                display="inline"
                marginRight="0.7em"
              >
                Description:
              </Typography>
              <Typography item display="inline">
                {item.description}
              </Typography>
              <Grid container display={"flex"} justifyContent={"flex-end"}>
                <Typography item inline>
                  Plus d'info sur la page n°
                </Typography>
                <Typography
                  color="secondary"
                  fontWeight='Bold'
                  fontSize={16}
                  inline
                >
                  {item.more_info}&nbsp;
                </Typography>
                <Typography item inline>
                  du pdf
                </Typography>
              </Grid>
              <TcsVignette tcsVignette={item.vignettes} />
            </Grid>
          : ""}
        </Card>
      ))}
  </Box>
  );
};