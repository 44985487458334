class SessionsApi {
    async createSession(admin_id, start_date, end_date, data) {
        const url = `${process.env.REACT_APP_BACK}/api/session/create/`
        const values = {
            "admin_id": admin_id,
            "session_number": data["session_number"],
            "start_date": start_date,
            "end_date": end_date,
            "formations_id": data["formation"]
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    async getSessionsByAuditId(admin_id, audits_id) {
        const url = `${process.env.REACT_APP_BACK}/api/session/getAllById/`;
        const values = {
            "admin_id": admin_id,
            "audits_id": audits_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    async getAllSessions(admin_id) {
        const url = `${process.env.REACT_APP_BACK}/api/session/getAll/`;
        const values = {
            "admin_id": admin_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }
}

export const sessionsApi = new SessionsApi();