import { useCallback,useEffect, useState } from 'react';
import { Autocomplete, Box, Card, CardHeader, Divider, Grid, Link, List, ListItem, ListItemText, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';

import { useMounted } from '../../../hooks/use-mounted';
import { useAuth } from '../../../hooks/use-auth';
import { userApi } from '../../../api/user-api';
import { formationsApi } from '../../../api/formation-api';
import { auditsApi } from '../../../api/audit-api';

export const FormationBasicDetails = (props) => {
  let { formation, setFormation } = props;
  
  const isMounted = useMounted();
  const { user } = useAuth();
  const [modify, setModify] = useState({
    "name": null,
    "tlms_id": null,
    "audits_id": null,
    "program_number": null
  });
  const [modifyText, setModifyText] = useState("")
  const [audits, setAudits] = useState([])
  const [units, setUnits] = useState([])
  const [selectedOption, setSelectedOption] = useState(null);

  const getAudits = useCallback(async () => {
    try {
      const data = await auditsApi.getAllEpp(user.admin_id)
      if (isMounted()) {
        setAudits(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const getAllUnits = useCallback(async () => {
    try {
      const data = await formationsApi.getAllUnitsSyna(user.admin_id)
      if (isMounted()) {
        setUnits(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  async function handleModify(field, data=null) {
    setModify(modify => ({
      ...modify,
      [field]: false})
    );
    let changeFormation = { ...formation };
    if (data) {
      changeFormation[field] = data;
    } else {
      changeFormation[field] = modifyText;
    }
    setFormation(changeFormation);
    await formationsApi.modifyFormation(user.admin_id, changeFormation);
  }

  async function handleModifyTlms(field, data) {
    setModify(modify => ({
      ...modify,
      [field]: false})
      );
    let changeFormation = { ...formation };
    changeFormation[field] = data;
    setFormation(changeFormation);
    if (!data || data !== -1) {
      await formationsApi.modifyFormation(user.admin_id, changeFormation);
    }
  }

  const handleOptionChange = async (event, option) => {
    setSelectedOption(option);
    let units_syna_formation_id = option ? option.formations_id : null
    await formationsApi.saveUnitsFormation(user.admin_id, formation.id, units_syna_formation_id)
  };

  useEffect(() => {
    setSelectedOption(units.find((option) => option.formations_id === formation.units_syna_formation_id))
  }, [units, formation])

  useEffect(() => {
    getAudits();
    getAllUnits();
  }, []);

  return (
    <Card>
      <CardHeader title="Informations"/>
      <Divider/>
      <List disablePadding>
      <ListItem
          sx={{
            px: 3,
            py: 1.5
          }}
        >
          <ListItemText
            sx={{
              display: 'flex',
              flexDirection: 'row',
              my: 0
            }}
            disableTypography
            primary={
              <Typography
                sx={{ minWidth: 180}}
                variant="subtitle2"
              >
                Numéro de programme
              </Typography>
            }
            secondary={(
              <Box
                sx={{
                  flex: 1,
                  mt: 0,
                  display: "flex",
                  maxWidth: 1000
                }}
              >
                {modify.program_number ?
                <TextField
                  size='small'
                  focused
                  autoFocus
                  defaultValue={formation.program_number}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleModify("program_number");
                    }
                  }}
                  onBlur={() => {
                    handleModify("program_number");
                  }}
                  onChange={(e) => setModifyText(e.target.value)}
                />
                : // ELSE
                <Typography
                  onDoubleClick={() => {setModify(modify => ({
                    ...modify,
                    program_number: true}));
                    setModifyText(formation.program_number)
                  }}
                  color="textSecondary"
                  variant="body2"
                >
                  {formation.program_number ? formation.program_number : "Veuillez rentrer un numéro de programme"}
                </Typography>
                }
              </Box>
            )}
          />
        </ListItem>
        <Divider/>
        <ListItem
          sx={{
            px: 3,
            py: 1.5
          }}
        >
          <ListItemText
            sx={{
              display: 'flex',
              flexDirection: 'row',
              my: 0
            }}
            disableTypography
            primary={
              <Typography
                sx={{ minWidth: 180}}
                variant="subtitle2"
              >
                Nom de la formation
              </Typography>
            }
            secondary={(
              <Box
                sx={{
                  flex: 1,
                  mt: 0,
                  display: "flex",
                  maxWidth: 1000
                }}
              >
                {modify.name ?
                <TextField
                  size='small'
                  focused
                  autoFocus
                  defaultValue={formation.name}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleModify("name");
                    }
                  }}
                  onBlur={() => {
                    handleModify("name");
                  }}
                  onChange={(e) => setModifyText(e.target.value)}
                />
                : // ELSE
                <Typography
                  onDoubleClick={() => {setModify(modify => ({
                    ...modify,
                    name: true}));
                    setModifyText(formation.name)
                  }}
                  color="textSecondary"
                  variant="body2"
                >
                  {formation.name}
                </Typography>
                }
              </Box>
            )}
          />
        </ListItem>
        <Divider/>
        <ListItem
          sx={{
            px: 3,
            py: 1.5
          }}
        >
          <ListItemText
            sx={{
              display: 'flex',
              flexDirection: 'row',
              my: 0
            }}
            disableTypography
            primary={
              <Typography
                sx={{ minWidth: 180}}
                variant="subtitle2"
              >
                Audit associé
              </Typography>
            }
            secondary={(
              <Box
                sx={{
                  flex: 1,
                  mt: 0,
                  display: "flex",
                  maxWidth: 1000
                }}
              >
                {!formation.audits_id || modify.audits_id ?
                <Select
                  value={formation.audits_id}
                  onChange={(e) => handleModify("audits_id", e.target.value)}
                >
                  {audits.map((audit) => (
                    <MenuItem key={audit.id} value={audit.id}>
                      {audit.name}
                    </MenuItem>
                  ))}
                </Select>
                : // ELSE
                <Typography
                  onDoubleClick={() => setModify(modify => ({
                    ...modify,
                    audits_id: true}))}
                  color="textSecondary"
                  variant="body2"
                >
                  {audits.find(audit => audit.id === formation.audits_id) ? audits.find(audit => audit.id === formation.audits_id).name : ""}
                </Typography>
                }
              </Box>
            )}
          />
        </ListItem>
        <Divider/>
        <ListItem
          sx={{
            px: 3,
            py: 1.5
          }}
        >
          <ListItemText
            sx={{
              display: 'flex',
              flexDirection: 'row',
              my: 0,
              alignItems: 'center'
            }}
            disableTypography
            primary={
              <Typography
                sx={{ minWidth: 180}}
                variant="subtitle2"
              >
                Type de programme
              </Typography>
            }
            secondary={(
              <Box
                sx={{
                  flex: 1,
                  mt: 0,
                  display: "flex",
                  maxWidth: 1000
                }}
              >
                <Grid 
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item> EPP </Grid>
                  <Grid item>
                    <Switch
                      inputProps={{ 'aria-label': 'controlled'}}
                      checked={formation.tlms_id ? true : false}
                      disabled={formation.used}
                      onChange={() => {
                        if (formation.tlms_id) {
                          handleModifyTlms("tlms_id", null)
                        } else {
                          handleModifyTlms("tlms_id", -1)
                        }
                      }}
                    />
                  </Grid>
                  <Grid item> Programme intégré</Grid>
                </Grid>
              </Box>
            )}
          />
        </ListItem>
        <Divider/>
        {formation.tlms_id ?
        <>
        <ListItem
        sx={{
            px: 3,
            py: 1.5
          }}
          >
          <ListItemText
            sx={{
              display: 'flex',
              flexDirection: 'row',
              my: 0
            }}
            disableTypography
            primary={
              <Typography
                sx={{ minWidth: 180}}
                variant="subtitle2"
              >
                Id de talentlms
              </Typography>
            }
            secondary={(
              <Box
              sx={{
                flex: 1,
                mt: 0,
                display: "flex",
                maxWidth: 1000
              }}
              >
                {modify.tlms_id ?
                <TextField
                  size='small'
                  focused
                  autoFocus
                  defaultValue={formation.tlms_id === -1 ? "" : formation.tlms_id}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleModify("tlms_id");
                    }
                  }}
                  onBlur={() => {
                    handleModify("tlms_id");
                  }}
                  onChange={(e) => setModifyText(e.target.value)}
                />
                : // ELSE
                <Typography
                  onDoubleClick={() => setModify(modify => ({
                    ...modify,
                    tlms_id: true}))}
                  color="textSecondary"
                  variant="body2"
                >
                  {formation.tlms_id === -1? "Entrez l'id tlms" : formation.tlms_id}
                </Typography>
                }
              </Box>
            )}
            />
        </ListItem>
        <Divider/>
        </>
         : ""}
         <ListItem
          sx={{
            px: 3,
            py: 1.5
          }}
        >
          <ListItemText
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              my: 0
            }}
            disableTypography
            primary={
              <Typography
                sx={{ minWidth: 180}}
                variant="subtitle2"
              >
                Choix du model d'unité
              </Typography>
            }
            secondary={(
              <>
                <Box
                  sx={{
                    flex: 1,
                    mt: 0,
                    display: "flex",
                    maxWidth: "40em"
                  }}
                >
                  <Autocomplete
                    style={{width: "100%"}}
                    options={units}
                    onChange={handleOptionChange}
                    getOptionLabel={(option) => option.name}
                    value={selectedOption}
                    renderInput={(params) => <TextField {...params} label="Unité" />}
                  />
                </Box>
                <Box
                  sx={{ml:2}}
                >
                  <Typography style={{ whiteSpace: "pre-wrap" }}>
                    {selectedOption && selectedOption.units}
                  </Typography>
                </Box>
              </>
            )}
          />
        </ListItem>
      </List>
    </Card>
  );
};
