import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAuth } from '../../../hooks/use-auth';
import { useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as moment from 'moment';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@mui/material';
import { wait } from '../../../utils/wait';
import { userApi } from '../../../api/user-api';
import { sessionsApi } from '../../../api/session-api';
import { useMounted } from '../../../hooks/use-mounted';
import { gtm } from '../../../lib/gtm';
import { useCallback, useEffect } from 'react';
import { formationsApi } from '../../../api/formation-api';
import { useNavigate } from "react-router-dom";

export const SessionCreate = (props) => {
  const { formationId } = props;
  const navigate = useNavigate();

  const isMounted = useMounted();
  const [formations, setFormations] = useState([])

  const { user } = useAuth();

  const getFormations = useCallback(async () => {
    try {
      const data = await formationsApi.getAllFormations(user.admin_id)
      if (isMounted()) {
        setFormations(data);
      }
    } catch (err) {
      console.error(err)
    }
  }, [isMounted]);

  useEffect(() => {
    getFormations();
    gtm.push({ event: 'page_view' });
  }, []);

  const formik = useFormik({
    initialValues: {
      session_number: '',
      start_date: '',
      end_date: '',
      formation: formationId,
      submit: null,
    },
    validationSchema: Yup.object({
      session_number: Yup.number().positive().integer().required(),
      start_date: Yup.date().required(),
      end_date: Yup.date().required(),
      formation: Yup.string().max(255).required(),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const id = await sessionsApi.createSession(user.admin_id, moment(values["start_date"]["$d"]).format("DD/MM/YYYY"), moment(values["end_date"]["$d"]).format("DD/MM/YYYY"), values)
        await wait(500);
        if (!id.id) {
          toast.error("Erreur lors de la création de la session")
          return
        }
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        toast.success('Session créée!');
      } catch (err) {
        console.error(err);
        toast.error('Une erreur est survenue!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
    >
      <Card>
        <CardHeader title="Session" />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                error={Boolean(formik.touched.session_number && formik.errors.session_number)}
                fullWidth
                helperText={formik.touched.session_number && formik.errors.session_number}
                label="Numéro de session"
                name="session_number"
                autoCapitalize='characters'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.session_number}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              {isMounted && (<LocalizationProvider
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  label="Date de début de session"
                  showTodayButton
                  clearable
                  inputFormat='DD/MM/YYYY'
                  InputAdornmentProps={{ position: 'start', variant: 'standard' }}
                  onChange={(date) => {
                    formik.setFieldValue('start_date', date)
                  }}
                  renderInput={(params) =>
                    <TextField
                    {...params}
                    />
                }
                  value={formik.values.start_date}
                />
              </LocalizationProvider>)}
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              {isMounted && (<LocalizationProvider
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  label="Date de fin de session"
                  showTodayButton
                  clearable
                  inputFormat='DD/MM/YYYY'
                  InputAdornmentProps={{ position: 'start', variant: 'standard' }}
                  onChange={(date) => {
                    formik.setFieldValue('end_date', date)
                  }}
                  renderInput={(params) =>
                    <TextField
                    {...params}
                    />
                }
                  value={formik.values.end_date}
                />
              </LocalizationProvider>)}
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(formik.touched.formation && formik.errors.formation)}
                fullWidth
                helperText={formik.touched.formation && formik.errors.formation}
                label="Choix de la formation"
                name="formation"
                select
                SelectProps={{ native: true }}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.formation}
                InputLabelProps={{ shrink: true }}
              >
                {formations.map((option) => (
                  <option
                    key={option.id}
                    value={option.id}
                  >
                    {option.name}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions
          sx={{
            flexWrap: 'wrap',
            m: -1
          }}
        >
          <Button
            disabled={formik.isSubmitting}
            type="submit"
            sx={{ m: 1 }}
            variant="contained"
          >
            Créer
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};