class TcsApi {
    async getInformationsTcs(admin_id, tcs_id) {
        const url = `${process.env.REACT_APP_BACK}/api/tcs/getInformations/`
        const values = {
            "admin_id": admin_id,
            "tcs_id": tcs_id
        }
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async modifyBasicInfoTcs(admin_id, tcs_info) {
        const url = `${process.env.REACT_APP_BACK}/api/tcs/modifyBasicInfo/`
        const values = {
            "admin_id": admin_id,
            "tcs_info": tcs_info
        }
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async updateVignetteGroup(admin_id, vignette_info) {
        const url = `${process.env.REACT_APP_BACK}/api/tcs/updateVignetteGroup/`
        const values = {
            "admin_id": admin_id,
            "vignette_info": vignette_info
        }
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async updateVignette(admin_id, vignette_info) {
        const url = `${process.env.REACT_APP_BACK}/api/tcs/updateVignette/`;
        const values = {
            "admin_id": admin_id,
            "vignette_info": vignette_info
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async updateVignetteRules(admin_id, vignette_info) {
        const url = `${process.env.REACT_APP_BACK}/api/tcs/updateVignetteRules/`;
        const values = {
            "admin_id": admin_id,
            "vignette_info": vignette_info
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async createVignetteGroup(admin_id, epp_id, number) {
        const url = `${process.env.REACT_APP_BACK}/api/tcs/createVignetteGroup/`;
        const values = {
            "admin_id": admin_id,
            "epp_id": epp_id,
            "number": number
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async createVignette(admin_id, rank, vignette_group_id) {
        const url = `${process.env.REACT_APP_BACK}/api/tcs/createVignette/`;
        const values = {
            "admin_id": admin_id,
            "rank": rank,
            "vignette_group_id": vignette_group_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async createVignetteRule(admin_id, rank, vignette_id) {
        const url = `${process.env.REACT_APP_BACK}/api/tcs/createVignetteRule/`;
        const values = {
            "admin_id": admin_id,
            "rank": rank,
            "vignette_id": vignette_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers : {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async deleteVignetteGroup(admin_id, mini_vignette_id) {
        const url = `${process.env.REACT_APP_BACK}/api/tcs/deleteVignetteGroup/`;
        const values = {
            "admin_id": admin_id,
            "mini_vignette_id": mini_vignette_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async deleteVignette(admin_id, vignette_id) {
        const url = `${process.env.REACT_APP_BACK}/api/tcs/deleteVignette/`;
        const values = {
            "admin_id": admin_id,
            "vignette_id": vignette_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async updateVignetteOrder(admin_id, vignette_order) {
        const url = `${process.env.REACT_APP_BACK}/api/tcs/updateVignetteOrder/`;
        const values = {
            "admin_id": admin_id,
            "vignette_order": vignette_order
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async deleteVignetteRule(admin_id, vignette_rule_id) {
        const url = `${process.env.REACT_APP_BACK}/api/tcs/deleteVignetteRule/`;
        const values = {
            "admin_id": admin_id,
            "vignette_rule_id": vignette_rule_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async getResultsBySession(admin_id, tcs_id) {
        const url = `${process.env.REACT_APP_BACK}/api/tcs/getResultBySessionAdmin/`;
        const values = {
            "admin_id": admin_id,
            "tcs_id": tcs_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }
}

export const tcsApi = new TcsApi();