import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { DashboardNavbar } from './dashboard-navbar';
import { DashboardSidebar } from './dashboard-sidebar';
import { Box } from '@mui/material';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 19,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280
  }
}));

export const DashboardLayout = (props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  return (
    <>
      <DashboardLayoutRoot>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
            backgroundColor: (theme) => theme.palette.mode === 'dark' ? "#1c2531" : '#F5F6FC'
          }}

        >
          <Outlet />
        </Box>
      </DashboardLayoutRoot>
        <DashboardSidebar
          onClose={() => setIsSidebarOpen(false)}
          open={isSidebarOpen}
        />
      <DashboardNavbar onOpenSidebar={() => setIsSidebarOpen(true)} />
    </>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};
