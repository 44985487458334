import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../../hooks/use-auth';

import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import { errorsApi } from '../../../api/error-api';
import { useMounted } from '../../../hooks/use-mounted';
import { MoreMenu } from '../../more-menu';
import { Scrollbar } from '../../scrollbar';
import { SeverityPill } from '../../severity-pill';

export const ErrorsCourses = () => {
  const isMounted = useMounted();
  const [errors, setErrors] = useState([]);
  const { user } = useAuth(); 
  const getInvoices = useCallback(async () => {
    try {
      const data = await errorsApi.getErrors(user.admin_id);

      if (isMounted()) {
        setErrors(data);
      }
    } catch (err) {
      console.error(err);
    }
  
  }, [isMounted]);

  useEffect(() => {
    getInvoices();
  }, [getInvoices]);

  return (
    <Card>
      <CardHeader
        title="Utilisateur associé à aucun cours"
      />
      <Divider />
      <Scrollbar>
        <Table sx={{ minWidth: 600 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                Id utilisateur
              </TableCell>
              <TableCell>
                Nom
              </TableCell>
              <TableCell>
                Email
              </TableCell>
              <TableCell>
                Id tlms
              </TableCell>
              <TableCell>
                Id syna
              </TableCell>
              <TableCell>
                Statut
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {errors? errors.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  {item.id}
                </TableCell>
                <TableCell>
                  {item.firstname + " " + item.lastname}
                </TableCell>
                <TableCell>
                  {item.email}
                </TableCell>
                <TableCell>
                  {item.tlms_id}
                </TableCell>
                <TableCell>
                  {item.syna_id}
                </TableCell>
                <TableCell>                  
                  <SeverityPill color='error'>
                    Error
                  </SeverityPill>
                </TableCell>
              </TableRow>
            )) : NULL }
          </TableBody>
        </Table>
      </Scrollbar>
      <TablePagination
        component="div"
        count={errors.length}
        onPageChange={() => {
        }}
        onRowsPerPageChange={() => {
        }}
        page={0}
        labelRowsPerPage='Lignes par page :'
        rowsPerPage={5}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};
