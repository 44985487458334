import { Card, CardHeader, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';


export const AuditsDetails = (props) => {
  const { result } = props;
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const align = mdUp ? 'horizontal' : 'vertical';

  return (
    <>
      <Card>
        <CardHeader title="Critères" />
        <Divider />
        <PropertyList 
        >
          {result.criteria ? result.criteria.map((item) => (
            <PropertyListItem
            sx={{
              p: 2,
              color: process.env.REACT_APP_SECONDARY_COLOR,
              typography: 'subtitle2'
            }}
              key={item.id}
              align={align}
              divider
              setMaxWidth='100%'
              label={"Numéro : " + item.number}
              value={
                <Grid container>
                  <Grid item sm={11}>
                    <Typography >
                      {item.criteria}
                    </Typography>
                  </Grid>
                  <Grid item sm={1}>
                    <Typography
                    sx={{ textAlign: 'right' }}>
                      {item.more_infos_page_number ? "Page n°" + item.more_infos_page_number : ""}
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
          )) : "No data" }
        </PropertyList>
    </Card>
    <Card>
      <CardHeader title="Objectifs" />
      <Divider />
      <PropertyList>
        {result.objectives ? result.objectives.map((item) => (
          <PropertyListItem
            key={item.id}
            align={align}
            divider
            label={"Place : " + item.place}
            value={item.description}
          />
        )) : "No data"}
      </PropertyList>
  </Card>
  </>
  );
};