import ArticleIcon from '@mui/icons-material/Article';
import { AdfScanner, FlakyOutlined } from '@mui/icons-material';
import ForumIcon from '@mui/icons-material/Forum';
import ListIcon from '@mui/icons-material/List';
import { Home as HomeIcon } from '../../icons/home';
import { Users as UsersIcon } from '../../icons/users';
import { BadgeCheckOutlined as Badge} from '../../icons/badge-check-outlined'
import { Plus} from '../../icons/plus'
import { Exclamation} from "../../icons/exclamation"

export const getSections = (t) => [
    {
      title: 'Analyses',
      items: [
        {
          title: t('Tableau de bord'),
          path: '/dashboard',
          icon: <HomeIcon fontSize="small" />
        },
      ]
    },
    {
      title: 'Commercial',
      items: [
        {
          title: t('Apprenants'),
          path: '/dashboard/customers',
          icon: <UsersIcon fontSize="small" />,
        },
        {
          title: t('Créer un apprenant'),
          path: '/dashboard/create',
          icon: <Plus fontSize="small" />,
        },
      ]
    },
    {
      title: 'Évaluations',
      items: [
        {
          title: t('Formations'),
          path: '/dashboard/formations',
          icon: <ArticleIcon fontSize="small" />,
        },
        {
          title: t('Audits'),
          path: '/dashboard/audits',
          icon: <Badge fontSize="small" />,
        },
        {
          title: t('TCS'),
          path: '/dashboard/tcs',
          icon: <FlakyOutlined fontSize="small" />,
        },
      ]
    },
    {
      title: 'Sessions',
      items: [
        {
          title: t('Liste des sessions'),
          path: '/dashboard/session',
          icon: <ListIcon fontSize="small" />
        }
      ]
    },
    {
      title: 'Autres',
      items: [
        {
          title: t('Erreurs'),
          path: '/dashboard/errors',
          icon: <Exclamation fontSize="small" />,
        },
        {
          title: t('Forum'),
          path: '/dashboard/forum',
          icon: <ForumIcon fontSize="small" />
        }
      ]
    }
];