import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/use-auth';
import { gtm } from '../../../lib/gtm';
import CloseIcon from '@mui/icons-material/Close';

import {
  AppBar,
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';

import { Scrollbar } from '../../../components/scrollbar';
import { useMounted } from '../../../hooks/use-mounted';
import { topicsApi } from '../../../api/topic-api';
import { Search as SearchIcon } from '../../../icons/search';

const sortOptions = [
  {
    label: 'Défaut',
    value: ''
  },
  {
    label: 'Date de création la plus proche',
    value: 'created_at_order|desc'
  },
  {
    label: 'Date de création la plus éloignée',
    value: 'created_at_order|asc'
  },
];

const sortPosts = [
  {
    label: 'Message le plus récent',
    value: 'created_at_order|asc'
  },
  {
    label: 'Message le plus ancien',
    value: 'created_at_order|desc'
  },
];

const categorieColor = {
  "Problèmes techniques": '#ff471a',
  "Question sur la formation": 'secondary',
  "Autre": 'grey',
  "Information": "blue"
}

const Forum = () => {
  const isMounted = useMounted();
  const [topics, setTopics] = useState([])
  const [filteredTopics, setFilteredTopics] = useState([])
  const [selectedAudits, setSelectedAudits] = useState([]);
  const [searchName, setSearchName] = useState([])
  const [sort, setSort] = useState('');
  const [sortPostsOpt, setSortPostsOpt] = useState('');
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [topicIndex, setTopicIndex] = useState(0);
  const [posts, setPosts] = useState([]);
  const [limit, setLimit] = useState(25);

  const { user } = useAuth(); 

  const handleClickOpen = async (id, index) => {
    let data = await topicsApi.getPostsByTopic(user.admin_id, id)
    setPosts(data);
    setTopicIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeSearch = event => {
    setSearchName(event.target.value)
    setFilteredTopics(topics.filter((el) => `${el.title}`.toLowerCase().includes(event.target.value.toLowerCase())))
    setPage(0);
  }

  const handleSortChange = (event, value) => {
    if (typeof event.target === 'undefined') {
      setSort(value);
    } else {
      setSort(event.target.value);
    }
  };

  const handleSortPostsChange = (event, value) => {
    if (typeof event.target === 'undefined') {
      setSortPostsOpt(value);
    } else {
      setSortPostsOpt(event.target.value);
    }
  }
  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const getTopics = useCallback(async () => {
    try {
      const data = await topicsApi.getAll(user.admin_id);
      if (isMounted()) {
        setTopics(data);
        setFilteredTopics(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);
  
  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => (order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy));

  const applySort = (customers, sort) => {
    const [orderBy, order] = sort.split('|');
    const comparator = getComparator(order, orderBy);
    const stabilizedThis = customers.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const newOrder = comparator(a[0], b[0]);
      if (newOrder !== 0) {
        return newOrder;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const applyPagination = (sessions, page, limit) => sessions
  .slice(page * limit, page * limit + limit);

  const sortedSessions = applySort(filteredTopics, sort);
  const paginatedSessions = applyPagination(sortedSessions, page, limit);

  const sortedPosts = applySort(posts, sortPostsOpt);

  useEffect(() => {
    getTopics();
    gtm.push({ event: 'page_view' });
  },
  []);

  useEffect(() => {
    if (selectedAudits.length) {
      setSelectedAudits([]);
    }
  },
  [topics]);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 11,
          
        }}
        // style={{ backgroundImage: `linear-gradient(to right, rgba(0, 224, 255, 1), rgba(0, 133, 255, 1))`, }}
      >
        <Container maxWidth="x1">
          <Box sx={{ mb: 5 }}>
              <Grid
                container
                justifyContent="space-between"
                spacing={3}
              >
                <Grid item>
                  <Typography 
                  sx={{
                   color:(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : "#1c2531"
                  }}
                  variant="h4">
                    Administration du forum
                  </Typography>
                </Grid>
              </Grid>
            </Box>
        <Card>
          
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              m: -1,
              p: 3
            }}
          >
            <Box
              sx={{
                m: 1,
                maxWidth: '100%',
                width: 450
              }}
            >
              <TextField
                value={searchName}
                onChange={handleChangeSearch}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  )
                }}
                placeholder="Chercher un sujet"
              />
            </Box>
            <Box
              sx={{
                m: 1,
                width: 240
              }}
            >
              <TextField
                label="Trier par"
                name="sort"
                onChange={handleSortChange}
                select
                SelectProps={{ native: true }}
                value={sort}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              >
                {sortOptions.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Box>
          </Box>
          <Scrollbar>
            <Table sx={{ minWidth: 700 }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ p: 2 }}>
                    <Typography
                       sx={{ mx: 3 }}
                       
                      //  color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                       fontWeight='Bold'
                       fontSize={13}
                    >
                      Sujet
                    </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography
                      //  color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                       fontWeight='Bold'
                       fontSize={13}
                    >
                      Date de création
                    </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography
                      //  color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                       fontWeight='Bold'
                       fontSize={13}
                    >
                      Audit associé
                    </Typography>
                    
                  </TableCell>
                  <TableCell>
                  <Typography
                      //  color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                       fontWeight='Bold'
                       fontSize={13}
                    >
                      Catégorie
                    </Typography>
                    
                  </TableCell>
                  <TableCell>
                  <Typography
                      //  color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                       fontWeight='Bold'
                       fontSize={13}
                    >
                      Créateur
                    </Typography>
                    
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedSessions ? paginatedSessions.map((item, index) => (
                  <TableRow
                    hover
                    key={index}
                  >
                    <TableCell>
                      <Box
                        padding={1}
                      >
                        <Button
                          onClick={() => handleClickOpen(item.id, index)}
                        >
                          <Box sx={{ ml: 1 }}>
                            <Typography
                              textAlign={"left"}
                              color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                              fontSize={18}
                              fontWeight='bold'
                            >
                              {item.title.charAt(0).toUpperCase() + item.title.slice(1)}
                            </Typography>
                            <Typography
                              textAlign={"left"}
                              color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                              fontSize={15}
                            >
                              {item.body}
                            </Typography>
                          </Box>
                        </Button>
                        <Dialog
                          // fullScreen
                          fullWidth
                          maxWidth={"md"}
                          onClose={handleClose}
                          open={open}
                        >
                          <AppBar sx={{ position: 'relative' }}>
                            <Toolbar>
                              <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                              >
                                <CloseIcon />
                              </IconButton>
                              <Typography
                                textAlign={'center'}
                                sx={{flex: 1}}
                                color="secondary"
                                variant="h4"
                                component="div"
                              >
                                {paginatedSessions[topicIndex].title.charAt(0).toUpperCase() + paginatedSessions[topicIndex].title.slice(1)}
                              </Typography>
                            </Toolbar>
                          </AppBar>
                          <Grid>
                            <DialogContent>
                              <Grid
                                marginTop={2}
                                >
                                <Card
                                >
                                  <Grid
                                    backgroundColor="#4A5076"
                                    > 
                                    <Typography
                                      color={"white"}
                                      display="inline"
                                      paddingTop={1}
                                      marginLeft={1}
                                      fontSize={13}
                                      fontWeight="bold"
                                      >
                                      {paginatedSessions[topicIndex].user}
                                    </Typography>

                                    <Typography
                                      color={"white"}
                                      display="inline"
                                      paddingTop={1}
                                      fontSize={14}
                                      >
                                      {" le " + paginatedSessions[topicIndex].created_at}
                                    </Typography>
                                    <Typography
                                      color={"white"}
                                      marginTop={1}
                                      marginLeft={3}
                                      typography={"h5"}
                                      >
                                      {paginatedSessions[topicIndex].title.charAt(0).toUpperCase() + paginatedSessions[topicIndex].title.slice(1)}
                                    </Typography>
                                    <Typography
                                      color={"white"}
                                      paddingTop={1}
                                      paddingBottom={2}
                                      marginLeft={3}
                                      >
                                      {paginatedSessions[topicIndex].body}
                                    </Typography>
                                  </Grid>
                                </Card>
                                <Grid
                                  marginTop={3}
                                  >
                                  <TextField
                                    label="Trier par"
                                    name="sort"
                                    onChange={handleSortPostsChange}
                                    select
                                    SelectProps={{ native: true }}
                                    value={sortPostsOpt}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    >
                                    {sortPosts.map((option) => (
                                      <option
                                      key={option.value}
                                      value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))}
                                  </TextField>
                                </Grid>
                                <Grid>
                                  {sortedPosts.map((item, index) => (
                                    <Grid
                                      marginTop={4}
                                      key={index}
                                      >
                                      <Card>
                                        <Grid
                                          backgroundColor="#EBEEFF"
                                          >
                                          <Typography
                                            display="inline"
                                            paddingTop={1}
                                            marginLeft={1}
                                            fontSize={13}
                                            fontWeight="bold"
                                            >
                                            {item.user}
                                          </Typography>
                                          <Typography
                                            display="inline"
                                            paddingTop={1}
                                            fontSize={14}
                                            >
                                            {" le " + item.created_at}
                                          </Typography>
                                          <Typography
                                            paddingTop={0.5}
                                            marginLeft={1}
                                            paddingLeft={2}
                                            paddingBottom={1}
                                            >
                                            {item.body}
                                          </Typography>
                                        </Grid>
                                      </Card>
                                      <Grid
                                        marginLeft={7}
                                        >
                                        {item.replies.map((reply, index_reply) => (
                                          <Grid
                                            marginTop={2}
                                            key={index_reply}
                                          >
                                            <Card>
                                              <Grid
                                                backgroundColor="#F7F8FF"
                                                >
                                                <Typography
                                                  display="inline"
                                                  paddingTop={1}
                                                  marginLeft={1}
                                                  fontSize={13}
                                                  fontWeight="bold"
                                                  >
                                                  {reply.user}
                                                </Typography>
                                                <Typography
                                                  display="inline"
                                                  paddingTop={1}
                                                  fontSize={14}
                                                  >
                                                  {" le " + reply.created_at}
                                                </Typography>
                                                <Typography
                                                  paddingTop={0.5}
                                                  marginLeft={1}
                                                  paddingLeft={2}
                                                  paddingBottom={1}
                                                  >
                                                  {reply.body}
                                                </Typography>
                                              </Grid>
                                            </Card>
                                          </Grid>
                                        ))}
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Grid>
                              </Grid>
                            </DialogContent>
                          </Grid>
                        </Dialog>
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Box sx={{ ml: 1 }}>
                        <Typography
                          color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                          fontSize={18}
                        >
                          {item.created_at}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Box sx={{ ml: 1 }}>
                        <Typography
                          color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                          fontSize={18}
                        >
                          {item.audit}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Box sx={{ ml: 1 }}>
                        <Typography
                          fontSize={18}
                          fontWeight='bold'
                          color={categorieColor[item.categorie]}
                        >
                          {item.categorie}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Box sx={{ ml: 1 }}>
                        <Typography
                          color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                          fontSize={18}
                        >
                          {item.user}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )) : NULL}
              </TableBody>
            </Table>
          </Scrollbar>
          <TablePagination
            component="div"
            count={topics.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            labelRowsPerPage='Lignes par page :'
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Card>
        </Container>
    </Box>
  </>
);
};

export default Forum;
