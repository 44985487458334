class ObjectivesApi {
    async createObjective(admin_id, description, place, audits_id) {
        const url = `${process.env.REACT_APP_BACK}/api/objective/create/`
        const values = {
            "admin_id": admin_id,
            "description": description,
            "place": place,
            "audits_id": audits_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };

    async deleteObjective(admin_id, objective_id) {
        const url = `${process.env.REACT_APP_BACK}/api/objective/delete/`
        const values = {
            "admin_id": admin_id,
            "objective_id": objective_id,
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };

    async changePlace(admin_id, objective_id, place) {
        const url = `${process.env.REACT_APP_BACK}/api/objective/changePlace/`
        const values = {
            "admin_id": admin_id,
            "objective_id": objective_id,
            "place": place
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async modifyNameObjective(admin_id, description, objective_id) {
        const url = `${process.env.REACT_APP_BACK}/api/objective/modifyName/`
        const values = {
            "admin_id": admin_id,
            "description": description,
            "objective_id": objective_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }
}

export const objectivesApi = new ObjectivesApi();