import { useCallback, useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useAuth } from '../../../hooks/use-auth';
import { useMounted } from '../../../hooks/use-mounted';
import { AuditsList } from '../../../components/dashboard/audits/audits-list';

import { gtm } from '../../../lib/gtm';
import { userApi } from '../../../api/user-api';


const Audits = () => {
  const isMounted = useMounted();
  const [audits, setAudits] = useState([])


  const { user } = useAuth(); 
  const getGraphics = useCallback(async () => {
    try {
      const data = await userApi.getEppByType(user.admin_id, "audit")
      if (isMounted()) {
        setAudits(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    getGraphics();
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 11
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ mb: 5 }}>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item>
                <Typography variant="h4">
                  Audits
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                xs={12}
              >
                <AuditsList
                  audits = {audits}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};


export default Audits;
