import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Drawer, Typography, Avatar, useMediaQuery } from '@mui/material';
import { DashboardSidebarSection } from './dashboard-sidebar-section';
import { useAuth } from '../../hooks/use-auth';
import { useLocation } from 'react-router-dom';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { getSections } from '../../modules/sidebar/sections';

export const DashboardSidebar = (props) => {
  const { onClose, open, anchorEl, ...other } = props;
  const { user } = useAuth();
  const location = useLocation();
  const { t } = useTranslation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    noSsr: true
  });

  const [openSide, setOpenSide] = useState(false);
  const handleOpen = () => setOpenSide(true);
  const handleClose = () => setOpenSide(false);

  const sections = useMemo(() => getSections(t), [t]);

  const content = (
    <Box
      {... other}
      anchorEl={anchorEl}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingTop: 9,
        backgroundColor: (theme) => theme.palette.mode === 'dark' ? process.env.REACT_APP_PRIMARY_COLOR : "#FFFFFF"
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            alignItems: 'center',
            borderRadius: 5,
            display: 'flex',
            overflow: 'hidden',
            p: 2,
            
          }}
          style={{ background: process.env.REACT_APP_PRIMARY_COLOR }}
        >
          <Avatar
            src={user.avatar}
            sx={{
              height: 50,
              width: 50
            }}
          />
          <Box sx={{ ml: 2 }}>
            <Typography
              color="#FFFFFF"
              variant="h6"

            >
              {user.firstname} {user.lastname}
            </Typography>
            <Typography
              color="#DCDEEA"
              variant="body2"
            >
              {process.env.REACT_APP_COMPANY_NAME}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider/>
      <Box
        sx={{ flexGrow: 1 }}
      >
        {sections.map((section) => (
          <DashboardSidebarSection
            key={section.title}
            path={location.pathname}
            title={section.title}
            sx={{
              mt: 1,
              '& + &': {
                mt: 0,
              }
            }}
            {...section}
          />
        ))}
      </Box>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'white',
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: (theme) => theme.palette.mode === 'dark' ? 1 : 0,
            color: process.env.REACT_APP_PRIMARY_COLOR,
            width: 280,
            
          }
        }}
        variant="persistent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open sidebar"
        onClick={handleOpen}
      >
        <MenuIcon />
      </IconButton>
      <Drawer 
        anchor="left"
        open={openSide} 
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: 'white',
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: (theme) => theme.palette.mode === 'dark' ? 1 : 0,
            color: process.env.REACT_APP_PRIMARY_COLOR,
            width: 280,
            
          }
        }}
      >
          {content}
      </Drawer>
    </>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
