import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Switch,
  Typography
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../../hooks/use-auth';
import { UserCircle as UserCircleIcon } from '../../icons/user-circle';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useSettings } from '../../hooks/use-settings';
  
const getValues = (settings) => ({
  direction: settings.direction,
  responsiveFontSizes: settings.responsiveFontSizes,
  theme: settings.theme
});

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { user } = useAuth();
  const { settings, saveSettings } = useSettings();
  const [values, setValues] = useState(getValues(settings));
  
  useEffect(() => {
    setValues(getValues(settings));
  }, [settings]);

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = () => {
    saveSettings(values);
  };

  const handleLogout = async () => {
    try {
      onClose?.();
      await logout();
      // router.push('/').catch(console.error);
      navigate('/')
      location.reload()
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      keepMounted
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 300 } }}
      transitionDuration={0}
      
      {...other}>
      <Box
        sx={{
          alignItems: 'center',
          p: 2,
          display: 'flex',
          
        }}
        style={{ background: '#f3f4f6'}}
      >
        <Avatar
          src={user.avatar}
          sx={{
            height: 50,
            width: 50
          }}
        >
          <UserCircleIcon fontSize="small" />
        </Avatar>
        <Box
          sx={{
            ml: 1
          }}
        >
          <Typography variant="h6">
            {user.firstname} {user.lastname}
          </Typography>
          <Typography
            variant="body2"
          >
            {process.env.REACT_APP_COMPANY_NAME}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Divider />
        <MenuItem>
          <LightModeIcon/>
          <Switch
            checked={values.theme === "dark" ? true : false}
            onChange={() => {
              let value = values.theme === "dark" ? "light" : "dark"
              handleChange('theme', value)
              handleSave()
            }}
          />
          <DarkModeIcon/>
        </MenuItem>
        {/* <Divider /> */}
        <MenuItem
          onClick={handleLogout}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" 
            sx={{ color: (theme) => theme.palette.mode === 'dark' ? '#f3f4f6' : process.env.REACT_APP_PRIMARY_COLOR}} />
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography variant="body1"
              sx={{ color: (theme) => theme.palette.mode === 'dark' ? '#f3f4f6' : process.env.REACT_APP_PRIMARY_COLOR}}
              >
                Déconnexion
              </Typography>
            )}
          />
        </MenuItem>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};
