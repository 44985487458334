import { useCallback, useEffect, useState } from 'react';
import { useParams, Link as RouterLink} from 'react-router-dom'
import { Box, Button, Card, Chip, Divider, Fab, InputLabel, Link, Grid, InputAdornment, Tab, Tabs, Typography, TextField, Select, MenuItem, CircularProgress, CardHeader } from '@mui/material';
import { useAuth } from '../../../hooks/use-auth';
import { useMounted } from '../../../hooks/use-mounted';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { gtm } from '../../../lib/gtm';
import { tcsApi } from '../../../api/tcs-api';
import { TcsDetails } from '../../../components/dashboard/tcs/tcs-details';
import { TcsModifyVignette } from '../../../components/dashboard/tcs/tcs-modify';
import { TcsStatistiques } from '../../../components/dashboard/tcs/tcs-statistiques';

const tabs = [
  { label: 'Détails', value: 'details' },
  { label: 'Statistiques', value: 'statistiques' }
];

const TcsInformationsById = () => {
  const {tcsId} = useParams();
  const isMounted = useMounted();
  const [tcsInformations, setTcsInformations] = useState([])
  const [loadingStats, setLoadingStats] = useState(true);
  const [modifyTcs, setModifyTcs] = useState(false);
  const [currentTab, setCurrentTab] = useState('details');
  
  const { user } = useAuth(); 

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const [modify, setModify] = useState({
    "syna_formations_id": null,
    "pdf_link": null,
    "image_link": null,
    "name": null
  });

  const [modifyText, setModifyText] = useState("")

  async function handleModify(field) {
    setModify(modify => ({
      ...modify,
      [field]: false})
    );
    let changetcsInformations = { ...tcsInformations };
    changetcsInformations["basic_info"][field] = modifyText;
    setTcsInformations(changetcsInformations);
    await tcsApi.modifyBasicInfoTcs(user.admin_id, changetcsInformations["basic_info"])
  }

  const getTcsInfo = useCallback(async () => {
    try {
      setLoadingStats(true)
      const data = await tcsApi.getInformationsTcs(user.admin_id, tcsId)
      if (isMounted()) {
        setTcsInformations(data);
        setLoadingStats(false)
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    getTcsInfo();
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      {!loadingStats?
        <>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
            mx: 3
          }}
        >
          <div>
            <Box sx={{ mb: 4 }}>
              <RouterLink
                to="/dashboard/tcs"
                style={{ textDecoration: 'none' }}
              >
                <Link
                  color="textPrimary"
                  component="a"
                  sx={{
                    alignItems: 'center',
                    display: 'flex'
                  }}
                >
                  <ArrowBackIcon
                    fontSize="small"
                    sx={{ mr: 1 }}
                  />
                  <Typography variant="subtitle2">
                    TCS
                  </Typography>
                </Link>
              </RouterLink>
            </Box>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid
                item
              >
                <div>
                  {modify.name ?
                  <TextField
                  label='Nom de la formation'
                  focused
                  fullWidth
                  autoFocus
                  defaultValue={tcsInformations.basic_info.name}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleModify("name");
                    }
                  }}
                  onBlur={() => {
                    handleModify("name");
                  }}
                  onChange={(e) => setModifyText(e.target.value)}
                  />
                  :
                  <Typography
                  variant='h4'
                  onDoubleClick={() => {setModify(modify => ({
                  ...modify,
                  name: true}));
                  setModifyText(tcsInformations.basic_info.name)
                }}
                >
                  {tcsInformations.basic_info.name}
                </Typography>
                  }
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mt: 0.6
                    }}
                  >
                    <Typography variant="subtitle2">
                      Id du TCS:
                    </Typography>
                    <Chip
                      label={tcsId}
                      size="small"
                      sx={{ ml: 1 }}
                    />
                  </Box>
                </div>
              </Grid>
            </Grid>
            <Card 
              sx={{
                m:2,
                p:2
              }}
            >
              {modify.syna_formations_id ?
              <TextField
              label='Id de la formation Syna'
              focused
              fullWidth
              autoFocus
              defaultValue={tcsInformations.basic_info.syna_formations_id}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleModify("syna_formations_id");
                }
              }}
              onBlur={() => {
                handleModify("syna_formations_id");
              }}
              onChange={(e) => setModifyText(e.target.value)}
              />
              : // ELSE
              <Typography
                onDoubleClick={() => {setModify(modify => ({
                ...modify,
                syna_formations_id: true}));
                setModifyText(tcsInformations.basic_info.syna_formations_id)
              }}
              >
                <span style={{marginRight: '3.5em'}}> Id(s) de la formation Syna :</span>
                {tcsInformations.basic_info.syna_formations_id}
              </Typography>
              }
            </Card>
            <Card 
              sx={{
                m:2,
                p:2
              }}
            >
              {modify.pdf_link ?
              <TextField
              label='Lien de la formation pdf'
              focused
              fullWidth
              autoFocus
              defaultValue={tcsInformations.basic_info.pdf_link}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleModify("pdf_link");
                }
              }}
              onBlur={() => {
                handleModify("pdf_link");
              }}
              onChange={(e) => setModifyText(e.target.value)}
              />
              : // ELSE
              <Typography
                onDoubleClick={() => {setModify(modify => ({
                ...modify,
                pdf_link: true}));
                setModifyText(tcsInformations.basic_info.pdf_link)
              }}
              >
                <span style={{marginRight: '4.1em'}}>Lien de la formation pdf :</span>
                {tcsInformations.basic_info.pdf_link}
              </Typography>
              }
            </Card>
            <Card 
              sx={{
                m:2,
                p:2
              }}
            >
              {modify.image_link ?
              <TextField
              label="Lien de l'image de la formation"
              focused
              fullWidth
              autoFocus
              defaultValue={tcsInformations.basic_info.image_link}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleModify("image_link");
                }
              }}
              onBlur={() => {
                handleModify("image_link");
              }}
              onChange={(e) => setModifyText(e.target.value)}
              />
              : // ELSE
              <Typography
                onDoubleClick={() => {setModify(modify => ({
                ...modify,
                image_link: true}));
                setModifyText(tcsInformations.basic_info.image_link)
              }}
              >
                <span style={{marginRight: '1em'}}>Lien de l'image de la formation :</span>
                {tcsInformations.basic_info.image_link}
              </Typography>
              }
            </Card>
          </div>
        </Box>
        <Box
          sx={{
            mx: 3
          }}
        >
          {currentTab === 'details' &&
            <Grid
              item
              sx={{ mt: 2, mr: 4}}
              display= 'flex'
              justifyContent="flex-end"
            > 
              {modifyTcs?
                <Button
                  variant="contained"
                  onClick={() => {
                    setModifyTcs(false)
                  }}
                >
                  Valider
                </Button>
              :
                <Button
                  variant="contained"
                  onClick={() => {
                    setModifyTcs(true)
                  }}
                >
                  Modifier
                </Button>
              }
            </Grid>
          }
          <Tabs
            indicatorColor="primary"
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="primary"
            value={currentTab}
            variant="scrollable"
            >
            {tabs.map((tab) => (
              <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              />
              ))}
          </Tabs>
          <Divider />
          <Box sx={{ mt: 3 }}>
            {currentTab === 'details' && (
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                >
                  {modifyTcs?
                  <TcsModifyVignette setTcsInformations={setTcsInformations} tcsInformations={tcsInformations}/>
                  :
                  <TcsDetails tcsDetails={tcsInformations.details}/>
                  }
                </Grid>
              </Grid>
            )}
            {currentTab === 'statistiques' && (
              <TcsStatistiques tcsId={tcsId}/>
            )}
          </Box>
        </Box>
        </>
        : 
        <CircularProgress
          color="inherit"
          size={50}
        />
        }
    </>
  );
};

export default TcsInformationsById;
