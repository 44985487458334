import { ErrorsCourses } from '../../components/dashboard/errors/errors-courses';
import { ErrorsLink } from '../../components/dashboard/errors/errors-link';

import { Box, Container, Grid, Typography } from '@mui/material';

const Errors = () => {
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 11
        }}
      >
        <Container maxWidth="x1">
          <Box sx={{ mb: 5 }}>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item>
                <Typography variant="h4">
                  Erreurs
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <ErrorsCourses/>
        </Container>
        <Container maxWidth="x1">
          <Box sx={{ mb: 8 }}>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
            >
            </Grid>
          </Box>
          <ErrorsLink/>
        </Container>
    </Box>
  </>
);
};

export default Errors;
