import { Box, Card, CircularProgress, Grid, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { tcsApi } from "../../../api/tcs-api";
import { useMounted } from "../../../hooks/use-mounted";
import { useEffect } from "react";
import { useAuth } from "../../../hooks/use-auth";

export const TcsStatistiques = (props) => {
  const { tcsId } = props;
  const isMounted = useMounted();
  const [tcsStats, setTcsStats] = useState([]);
  const [loadingStats, setLoadingStats] = useState(true);

  const { user } = useAuth(); 

  const getTcsStats = useCallback(async () => {
    try {
      setLoadingStats(true)
      const data = await tcsApi.getResultsBySession(user.admin_id, tcsId)
      if (isMounted()) {
        setTcsStats(data);
        setLoadingStats(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    getTcsStats();
  }, []);

  return (
    <Box sx={{mb: 15}}>
      {!loadingStats ?
        tcsStats.map((session, index) => (
          <Card index={index}>
            <Grid
              container
              justifyContent={'flex-end'}
              marginTop={3}
            >
              <Typography fontSize={20}>
                Début de session :
              </Typography>
              <Typography fontSize={20} sx={{ml:0.5, mr: 2}} fontWeight={"bold"}>
                {session.turn_1.start_session}
              </Typography>
              <Typography fontSize={20}>
                Fin de session :
              </Typography>
              <Typography fontSize={20} sx={{ml:0.5, mr:4}} fontWeight={"bold"}>
                {session.turn_1.end_session}
              </Typography>
            </Grid>
          </Card>
        ))
      :
        <CircularProgress/>
      }
    </Box>
  );
};