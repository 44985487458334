import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, tableCellClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

export const TcsVignette = (props) => {
  const { tcsVignette } = props;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#1B2445",
      color: "white",
      borderLeft: "2px solid rgba(224, 224, 224, 1)"
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <>
    {tcsVignette.map((item, index) => (
      <Grid key={item.id} sx={{mt: 4}}>
        <Typography
          align='center'
          variant='h5'
          marginBottom={2}
        >
          {item.title_vignette}
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{minWidth: 700}} style={{ tableLayout: 'fixed' }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  {item.title_first_rule}
                </StyledTableCell>
                <StyledTableCell>
                  {item.title_second_rule}
                </StyledTableCell>
                <StyledTableCell>
                  {item.title_response}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {item.vignette_rules.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell
                    sx={{borderLeft: "2px solid rgba(224, 224, 224, 1)"}}
                  >
                    {row.first_rule}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{borderLeft: "2px solid rgba(224, 224, 224, 1)"}}
                  >{row.second_rule}</StyledTableCell>
                  <StyledTableCell
                    sx={{
                      borderLeft: "2px solid rgba(224, 224, 224, 1)",
                      borderRight: "2px solid rgba(224, 224, 224, 1)"
                    }}
                  >{row.good_answer}</StyledTableCell>
                </StyledTableRow>
              ))}
              <StyledTableRow>
                <StyledTableCell
                  sx={{borderLeft: "2px solid rgba(224, 224, 224, 1)"}}
                > {item.minus_two_answer}<br/> {item.minus_one_answer}</StyledTableCell>
                <StyledTableCell> {item.zero_answer}</StyledTableCell>
                <StyledTableCell
                  sx={{borderRight: "2px solid rgba(224, 224, 224, 1)"}}
                > {item.plus_one_answer}<br/> {item.plus_two_answer}</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    ))}
    </>
  );
}