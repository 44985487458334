import { useCallback, useEffect, useState } from 'react';
import { useParams, Link as RouterLink} from 'react-router-dom'
import { Box, Button, Chip, Divider, Link, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useAuth } from '../../../hooks/use-auth';
import { useMounted } from '../../../hooks/use-mounted';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FormationBasicDetails } from '../../../components/dashboard/formations/formation-basic-detail';
import { gtm } from '../../../lib/gtm';
import { formationsApi } from '../../../api/formation-api';

const tabs = [
  { label: 'Détails', value: 'details' },
  // { label: 'Statistiques', value: 'statistiques' }
];

const FormationDetails = () => {
  const {formationId} = useParams();
  const isMounted = useMounted();

  const [formation, setFormation] = useState([]);
  const [currentTab, setCurrentTab] = useState('details');
  const { user } = useAuth(); 

  const getFormationData = useCallback(async () => {
    try {
      const data = await formationsApi.getFormationData(user.admin_id, formationId)
      if (isMounted()) {
        setFormation(data)
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    getFormationData();
    gtm.push({ event: 'page_view' });
  }, []);

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 11,
        mx: 3
      }}
    >
      <Box sx={{ mb: 5 }}>
        <RouterLink
          to="/dashboard/formations"
          style={{ textDecoration: 'none' }}
        >
          <Link
            color="textPrimary"
            component="a"
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <ArrowBackIcon
              fontSize="small"
              sx={{ mr: 1 }}
            />
            <Typography variant="subtitle2">
              Formations
            </Typography>
          </Link>
        </RouterLink>
      </Box>
      <Grid
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid
          item
          sx={{
            alignItems: 'center',
            display: 'flex',
            overflow: 'hidden'
          }}
        >
          <div>
            <Typography variant="h4">
              {formation.name}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Typography variant="subtitle2">
                Id de la formation:
              </Typography>
              <Chip
                label={formationId}
                size="small"
                sx={{ ml: 1 }}
              />
            </Box>
          </div>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{ mt: 2, mr: 4}}
        display= 'flex'
        justifyContent="flex-end"
      > 
        <Box
          sx={{
            mr: 2,
          }}
        >
          <RouterLink
            to={`/dashboard/session/create`}
            style={{ textDecoration: 'none' }}
            state={{
              from:`/dashboard/formations/${formationId}`,
              formationId: formationId
            }}
          >
            <Button
              variant="contained"
            >
              Créer une session
            </Button>
          </RouterLink>
        </Box>
      </Grid>
      <Tabs
        indicatorColor="primary"
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="primary"
        value={currentTab}
        variant="scrollable"
        >
        {tabs.map((tab) => (
          <Tab
          key={tab.value}
          label={tab.label}
          value={tab.value}
          />
          ))}
      </Tabs>
      <Divider />
      <Box sx={{ mt: 3 }}>
        {currentTab === 'details' && (
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <FormationBasicDetails
                formation = {formation}
                setFormation = {setFormation}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default FormationDetails;
