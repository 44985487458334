import { Box, CircularProgress, Collapse, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Typography} from "@mui/material";
import { coursesApi } from '../../../api/course-api';
import { useMounted } from '../../../hooks/use-mounted';
import { useCallback, useState, useEffect } from 'react';
import { useAuth } from '../../../hooks/use-auth';

export const CustomerResponseTCS = (props) => {
  const { open, handleClose, course_id } = props;
  const isMounted = useMounted();
  const [resultTcs, setResultTcs] = useState(null)
  const [collapseStatesFirstTurn, setCollapseStatesFirstTurn] = useState([]);
  const [collapseStatesSecondTurn, setCollapseStatesSecondTurn] = useState([]);
  const { user } = useAuth(); 

  const getAnswer = useCallback(async () => {
    try {
      const data = await coursesApi.getTcsResult(user.admin_id, course_id)
      if (isMounted()) {
        setResultTcs(data)
        setCollapseStatesFirstTurn(new Array(data.tour_1.mini_vignette.length).fill(false));
        setCollapseStatesSecondTurn(new Array(data.tour_2.mini_vignette.length).fill(false));
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const handleCollapseToggleFirstTurn = (index) => {
    setCollapseStatesFirstTurn((prevState) => {
      const updatedStates = [...prevState];
      updatedStates[index] = !updatedStates[index];
      return updatedStates;
    });
  };

  const handleCollapseToggleSecondTurn = (index) => {
    setCollapseStatesSecondTurn((prevState) => {
      const updatedStates = [...prevState];
      updatedStates[index] = !updatedStates[index];
      return updatedStates;
    });
  };

  useEffect(() => {
    getAnswer();
  }, []);

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle
            variant="h2"
            align='center'
        >
          Liste des réponses
        </DialogTitle>
        {resultTcs ?
          <DialogContent>
            <DialogContentText>
              <Grid>
                <Typography
                  variant="h3"
                  color="secondary"
                >
                  Tour 1 :
                </Typography>
              </Grid>
              <Grid container alignItems="center" spacing={2} marginTop={'0.3em'}>
                <Grid item>
                  <Typography sx={{fontSize:20, fontWeight:'bold'}}>
                    Score de l'apprenant {resultTcs.tour_1.correct_answers} / {resultTcs.tour_1.total_questions}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{fontSize:18, fontStyle:'italic', mt: 0.3}}>
                    ({resultTcs.tour_1.percentage}%)
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ mt: '1em' }}>
                {collapseStatesFirstTurn.length > 0 &&
                  resultTcs.tour_1.mini_vignette.map((mini_vignette, index) => (
                    <Grid
                      key={index}
                      container
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      sx={{
                        p: 1,
                        backgroundColor: index % 2 === 0 ? 'lightgrey' : 'white',
                      }}
                    >
                      <Grid item>
                        <Typography sx={{fontWeight: 'bold', fontSize: 22}} onClick={() => handleCollapseToggleFirstTurn(index)}>
                          Mini vignette {index + 1}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Collapse in={collapseStatesFirstTurn[index]} timeout="auto" unmountOnExit>
                          <Typography sx={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center', mb: 2 }}>
                            {mini_vignette.score} / {mini_vignette.nb_answer}
                          </Typography>
                          {mini_vignette.result.map((vignette, indexVignette) => (
                            <Grid container justifyContent="center" alignItems="center" key={indexVignette}>
                              <Typography sx={{mb: 0.7}}>
                                {indexVignette+1}.
                              </Typography>
                              {[-2, -1, 0, 1, 2].map((value, indexResult) => (
                                <Typography
                                  key={indexResult}
                                  sx={{ mx: 6, mb: 1, textAlign: 'center', fontSize: 20, fontWeight: 'bold',
                                    color: value === vignette.good_answer ? "green" : value === vignette.answer ? "red" : "grey"
                                  }}
                                >
                                  {value}
                                </Typography>
                              ))}
                            </Grid>
                          ))}
                        </Collapse>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
              <Grid sx={{mt: 6}}>
                <Typography
                  variant="h3"
                  color="secondary"
                >
                  Tour 2 :
                </Typography>
              </Grid>
              <Grid container alignItems="center" spacing={2} marginTop={'0.3em'}>
                <Grid item>
                  <Typography sx={{fontSize:20, fontWeight:'bold'}}>
                    Score de l'apprenant {resultTcs.tour_2.correct_answers} / {resultTcs.tour_2.total_questions}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{fontSize:18, fontStyle:'italic', mt: 0.3}}>
                    ({resultTcs.tour_2.percentage}%)
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ mt: '1em' }}>
                {collapseStatesSecondTurn.length > 0 &&
                  resultTcs.tour_2.mini_vignette.map((mini_vignette, index) => (
                    <Grid
                      key={index}
                      container
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      sx={{
                        p: 1,
                        backgroundColor: index % 2 === 0 ? 'lightgrey' : 'white',
                      }}
                    >
                      <Grid item>
                        <Typography sx={{fontWeight: 'bold', fontSize: 22}} onClick={() => handleCollapseToggleSecondTurn(index)}>
                          Mini vignette {index + 1}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Collapse in={collapseStatesSecondTurn[index]} timeout="auto" unmountOnExit>
                          <Typography sx={{ fontSize: 13, fontWeight: 'bold', textAlign: 'center', mb: 2 }}>
                            {mini_vignette.score} / {mini_vignette.nb_answer}
                          </Typography>
                          {mini_vignette.result.map((vignette, indexVignette) => (
                            <Grid container justifyContent="center" alignItems="center" key={indexVignette}>
                              <Typography sx={{mb: 0.7}}>
                                {indexVignette+1}.
                              </Typography>
                              {[-2, -1, 0, 1, 2].map((value, indexResult) => (
                                <Typography
                                  key={indexResult}
                                  sx={{ mx: 6, mb: 1, textAlign: 'center', fontSize: 20, fontWeight: 'bold',
                                    color: value === vignette.good_answer ? "green" : value === vignette.answer ? "red" : "grey"
                                  }}
                                >
                                  {value}
                                </Typography>
                              ))}
                            </Grid>
                          ))}
                        </Collapse>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            </DialogContentText>
          </DialogContent>
        :
          <Box
            display= "flex"
            justifyContent= "center"
            alignItems= "center"
            padding={5}
          >
            <CircularProgress
              color="inherit"
              size={100}
            />
          </Box>
        }
        </Dialog>
    </>
  );
};