import { Box, Card, Divider, Grid, IconButton, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { DataGrid, frFR, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import ProgressBar from '../session/progress-bar';
import { KeyboardArrowDown, KeyboardArrowUp, ToggleOffOutlined, ToggleOn, Visibility, ViewStream, ViewList } from '@mui/icons-material';
import { coursesApi } from '../../../api/course-api';
import { useAuth } from '../../../hooks/use-auth';
import { makeStyles } from '@material-ui/styles';
import { CustomerCoursesGrid } from './customer-courses-grid';
import { Link as RouterLink } from 'react-router-dom'
import { CustomerResponseAudit } from './customer-response-audit';
import { CustomerResponseTCS } from './customer-reponse-tcs';
import LockClockIcon from '@mui/icons-material/LockClock';
import Swal from 'sweetalert2'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import toast from 'react-hot-toast';
import moment from 'moment';
import { formationsApi } from '../../../api/formation-api';
import { facturationApi } from '../../../api/facturation-api';
import * as XLSX from 'xlsx';
import LinearProgress from '@mui/material/LinearProgress';

export const CustomerCourses = (props) => {
  const { customer, setCustomer, customerId } = props;
  const [rows, setRows] = useState([]);
  const [openAuditId, setOpenAuditId] = useState(null)
  const [startSession, setStartSession] = useState(null)
  const [endSession, setEndSession] = useState(null)
  const [auditType, setAuditType] = useState(null)
  const [courseId, setCourseId] = useState(null);
  const [viewMode, setViewMode] = useState('list');

  const { user } = useAuth(); 

  const useStyles = makeStyles({
    disableRow: {
      backgroundColor: '#CBCBCB',
      "&.MuiDataGrid-row:hover": {
        backgroundColor: '#CBCBCB',
      },
    },
    normalRow: {
      backgroundColor: 'white',
      "&.MuiDataGrid-row:hover": {
        backgroundColor: 'white',
      },
    },
  });

  const classes = useStyles();

  async function handleDesactivate(index, auditId, startDate, endDate) {
    let items = [...customer.audits];
    items[index].activated = false;
    await coursesApi.desactivateCourse(user.admin_id, auditId, customerId, startDate, endDate)
    setCustomer({
      ...customer,
      audits: items
    });
  }

  async function handleActivate(index, auditId, startDate, endDate) {
    let items = [...customer.audits];
    items[index].activated = true;
    await coursesApi.activateCourse(user.admin_id, auditId, customerId, startDate, endDate)
    setCustomer({
      ...customer,
      audits: items
    });
  }
  
  async function handleUnlockSecondTurn(course_id) {
    Swal.fire({
      title: 'Voulez-vous vraiment débloquer le second tour?',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let answer = await coursesApi.unlockSecondTurn(user.admin_id, course_id)
        if (answer.msg == 'unlocked') {
          Swal.fire({
            title: "Tour débloqué",
            icon: 'success',
          })
        } else if (answer.msg == 'impossible unlock') {
          Swal.fire({
            title: "Impossible de débloquer le tour maintenant",
            icon: 'warning',
          })
        } else if (answer.msg == 'already unlock') {
          Swal.fire({
            title: "Second tour déjà débloqué",
            icon: 'error',
          })
        }
      }
    })
  }

  const handleViewModeChange = (event, newViewMode) => {
    if (newViewMode !== null) {
      setViewMode(newViewMode);
    }
  };

  const handleClose = () => {
    setOpenAuditId(null);
  };

  const handleClickOpen = (auditId, start_session, end_session, audit_type, course_id) => {
    setOpenAuditId(auditId);
    setStartSession(start_session);
    setEndSession(end_session);
    setAuditType(audit_type);
    setCourseId(course_id);
  };

  const setLoading = (course_id, state) => {
    const updateRowData = rows.map((row) => {
      if (row.course_id === course_id) {
        return {
          ...row,
          loading: state
        }
      }
      return row
    })
    setRows(updateRowData)
  }

  const handleLogs = async (course_id) => {
    try {
      setLoading(course_id, true);
      const formation = await formationsApi.getAllInformationsByAuditId(user.admin_id, course_id)
      const units = await facturationApi.factureOnCrm(formation.syna_id, formation.formation_id, formation.start_session, formation.end_session, formation.tlms_id, formation.subscribed_at, formation.email, formation.units);
      const tableExcel = [];

      units.forEach((element) => {
        tableExcel.push(['Nom', 'Prénom', 'Email du participant', 'ID de la session', 'Nom du module', 'Connexion - Date et heure', 'Déconnexion - Date et heure', 'Temps passé', 'Progression (%)']);
        element.codeco.forEach((cdc) => tableExcel.push([`${formation.lastname}`, `${formation.firstname}`, `${formation.email}`, `${formation.program_number}`, `${cdc.module_name}`, `${cdc.connect}`, `${cdc.disconnect}`, `${cdc.duration}`, `${cdc.progression}`]));
        tableExcel.push(['', '', '', '', `${element.expected_time}`, '', '', `${element.total_time}`]);
        tableExcel.push([]);
      });
      const formattedDate = moment(formation.subscribed_at).format("DD/MM/YYYY");
      const formattedTime = moment(formation.subscribed_at).format("HH:mm:ss");
      // tableExcel.push(["Date d'inscription", `${formattedDate} à ${formattedTime}`]);
      const worksheet = XLSX.utils.aoa_to_sheet(
        tableExcel
      );

      const colNum = XLSX.utils.decode_col('H');
      const fmt = 'h:mm:ss';

      const range = XLSX.utils.decode_range(worksheet['!ref']);
      for (let i = range.s.r + 1; i <= range.e.r; ++i) {
        const ref = XLSX.utils.encode_cell({ r: i, c: colNum });
        if (worksheet[ref] && worksheet[ref].v.includes(':')) {
          worksheet[ref].z = fmt;
        }
      }

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, worksheet, 'Logs');

      /* generate XLSX file and send to client */
      XLSX.writeFile(wb, `User ${formation.lastname} ${formation.firstname}.xlsx`);
      setLoading(course_id, false);
    }
    catch (err) {
      console.error(err);
      toast.error('Il y a eu un souci lors de la génération de l\'attestation !');
      setLoading(course_id, false);
    }
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
       <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );

  useEffect(() => {
    setRows(customer.audits.map((item, index) => ({
      id: index,
      action_type: item.action_type,
      activated: item.activated,
      audit_id: item.audit_id,
      audit_name: item.audit_name,
      audit_type: item.audit_type,
      course_id: item.course_id,
      end_session: item.end_session,
      loading: false,
      format: item.format,
      prediction: item.prediction,
      program_number: item.program_number,
      progression: item.progression,
      spent_time: item.spent_time,
      session_number: item.session_number,
      sessions_id: item.sessions_id,
      start_session: item.start_session,
      state: item.state,
      step: item.step,
    })));
  }, [customer]);

  const columns = [
    {
      field: 'audit_name',
      headerName: 'Nom du cours',
      renderCell: (cellValues) => (
        <Tooltip title={cellValues.row.audit_name}>
          <RouterLink
            to={`/dashboard/session/1`}
            state={{
              data_from: {
                auditId: cellValues.row.audit_id,
                startSession: cellValues.row.start_session,
                endSession: cellValues.row.end_session,
                sessions_id: cellValues.row.sessions_id,
                from: "/dashboard/customers/" + customerId
              }
            }}
            style={{ textDecoration: 'none', color:process.env.REACT_APP_PRIMARY_COLOR}}
            >
            {cellValues.row.audit_name}
          </RouterLink>
        </Tooltip>
      ),
      width: 350,
    },
    {
      field: 'program_number',
      headerName: 'N° programme',
      width: 120
    },
    {
      field: 'action_type',
      headerName: 'Type de formation',
      width: 160,
    },
    {
      field: 'format',
      headerName: 'Format',
      width: 130,
    },
    {
      field: 'start_session',
      headerName: 'Début session',
      width: 120,
    },
    {
      field: 'end_session',
      headerName: 'Fin session',
      width: 110,
    },
    {
      field: 'progression',
      headerName: 'Progression',
      renderCell: (cellValues) => (
        <ProgressBar
          value={cellValues.row.progression}
        />
      ),
      width: 140,
    },
    {
      field: 'state',
      headerName: 'Etat',
      width: 130,
    },
    {
      field: 'spent_time',
      headerName: 'Temps passé',
      width: 120,
    },
    {
      field: 'answer',
      headerName: 'Action',
      renderCell: (cellValues) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={() => handleClickOpen(cellValues.row.audit_id, cellValues.row.start_session, cellValues.row.end_session, cellValues.row.audit_type, cellValues.row.course_id)}>
            <Visibility/>
          </IconButton>
          {cellValues.row.activated ?
            <IconButton onClick={() => handleDesactivate(cellValues.row.id, cellValues.row.audit_id, cellValues.row.start_session, cellValues.row.end_session)}>
              <ToggleOn/>
            </IconButton>
            :
            <IconButton onClick={() => handleActivate(cellValues.row.id, cellValues.row.audit_id, cellValues.row.start_session, cellValues.row.end_session)}>
              <ToggleOffOutlined/>
          </IconButton>
          }
          {cellValues.row.loading?
            <LinearProgress sx={{width: "2.75em", ml: '6%', mr: '4%'}}/>
          :
          <IconButton onClick={() => handleLogs(cellValues.row.course_id)}>
            <FileDownloadIcon/>
          </IconButton>
          }
          {user.super_admin?
            <IconButton onClick={() => handleUnlockSecondTurn(cellValues.row.course_id)}>
              <LockClockIcon/>
            </IconButton>
            : ""
          }
        </div>
      ),
      width: 160
    }
  ]

  return (
    <Grid>
      {viewMode === "list" && (
        <Card>
          <Grid container>
            <Grid item sm={12} sx={{textAlign:'right', p:2, pb: 0}}>
              <ToggleButtonGroup
                value={viewMode}
                exclusive
                onChange={handleViewModeChange}
              >
                <ToggleButton value="list">
                  <ViewList /> Liste
                </ToggleButton>
                <ToggleButton value="grid">
                  <ViewStream /> Grille
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
           <Grid item sm={12}>
             <DataGrid
               density="standard"
               localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
               autoHeight
               rows={rows}
               disableRowSelectionOnClick
               getRowClassName={(params) => {
                 if (params.row.activated === false) {
                   return classes.disableRow
                 }
                 return classes.normalRow;
               }}
               disableHoverListener
               columns={columns}
               pageSize={20}
               pageSizeOptions={[5]}
               components={{ Toolbar: CustomToolbar }}
             />
             {openAuditId !== null && (
               auditType === "TCS" ?
               <CustomerResponseTCS
                 open={true}
                 handleClose={handleClose}
                 course_id={courseId}
               />
               :
               <CustomerResponseAudit
                 open={true}
                 handleClose={handleClose}
                 audit_id={openAuditId}
                 customer_id={customerId}
                 start_session={startSession}
                 end_session={endSession}
               />
             )}
           </Grid>
          </Grid>
        </Card>
      )}
      {viewMode === 'grid' && (
        customer.audits.map((option, index) => (
          <Card sx={{'mb': 3}}>
            <Grid container>
              {index === 0?
                 <Grid item sm={12} sx={{textAlign:'right', p:2, pb: 0}}>
                 <ToggleButtonGroup
                   value={viewMode}
                   exclusive
                   onChange={handleViewModeChange}
                 >
                   <ToggleButton value="list">
                     <ViewList /> Liste
                   </ToggleButton>
                   <ToggleButton value="grid">
                     <ViewStream /> Grille
                   </ToggleButton>
                 </ToggleButtonGroup>
               </Grid>
              : ""
              }
              <Grid
                item
                xs={12}
                key={option.audit_id}
                padding={2}
                >
                <CustomerCoursesGrid
                  customer={customer}
                  setCustomer={setCustomer}
                  customer_id={customerId}
                  index={index}
                  />
              </Grid>
            </Grid>
          </Card>
        ))
      )}
    </Grid>
  );
};