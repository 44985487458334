import { useState, useEffect, useCallback } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Select,
  MenuItem,
  Typography
} from '@mui/material';
import { userApi } from '../../../api/user-api';
import { useMounted } from '../../../hooks/use-mounted';
import { Scrollbar } from '../../scrollbar';
import { SeverityPill } from '../../severity-pill';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import moment from 'moment';

export const CustomerLogs = (props) => {
  const {admin_id, customerid} = props;
  const isMounted = useMounted();
  const [logs, setLogs] = useState([]);
  const [sortDirection, setSortDirection] = useState('desc');
  const [selectedType, setSelectedType] = useState(null);

  const types = [...new Set(logs.map((log) => log.type))];
  
  const handleSortClick = () => {
    const newSortDirection = sortDirection === 'desc' ? 'asc' : 'desc';
    setSortDirection(newSortDirection)
  }

  const descendingComparator = (a, b) => {
    const aDate = moment(a["created_at"], 'DD/MM/YYYY HH:mm:ss').toISOString();
    const bDate = moment(b["created_at"], 'DD/MM/YYYY HH:mm:ss').toISOString();
    if (bDate < aDate) {
      return -1;
    }
    if (bDate > aDate) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order) => (order === 'desc'
  ? (a, b) => descendingComparator(a, b)
  : (a, b) => -descendingComparator(a, b));

  const applySort = (logsToSort, sort) => {
    const comparator = getComparator(sort);
    const stabilizedThis = logsToSort.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const newOrder = comparator(a[0], b[0]);
      if (newOrder !== 0) {
        return newOrder;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const sortedLogs = applySort(logs, sortDirection);

  const getLogs = useCallback(async () => {
    try {
      const data = await userApi.getUsersActivities(admin_id, customerid);

      if (isMounted()) {
        setLogs(data);
      }
    } catch (err) {
      console.error(err);
    }
  
  }, [isMounted]);

  useEffect(() => {
    getLogs();
  }, [getLogs]);

  return (
    <Card {...props}>
      <CardHeader
        title="Activités"
      />
      <Divider />
      <Scrollbar>
      <Select
        value={selectedType}
        displayEmpty="true"
        onChange={(event) => setSelectedType(event.target.value)}
        sx={{
          minWidth: '10em',
          maxHeight: '3.5em',
          backgroundColor: '#F8F8F8',
          borderRadius: '4px',
          padding: '8px',
          margin: '2%',
          border: 'none',
          '&:hover': {
            backgroundColor: '#E8E8E8',
          },
          '&:focus': {
            outline: 'none',
            backgroundColor: '#F0F0F0',
          },
        }}
        IconComponent={
          ArrowDropDown
        }
      >
        <MenuItem value={null}>Tous les types</MenuItem>
        {types.map((type) => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </Select>
      <Table>
        <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography>
                  Type
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>
                  Description
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  onClick={handleSortClick}
                  style={{ cursor: 'pointer' }}
                >
                  Date de création
                  {sortDirection === 'desc' ? (
                    <ArrowDropDown fontSize="small" />
                  ) : (
                    <ArrowDropUp fontSize="small" />
                  )}
                </Typography>
                {sortDirection === 'desc' ? ' (plus récent)' : ' (plus ancien)'}
              </TableCell>
              <TableCell align="center">
                <Typography>
                  ID du cours
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedLogs
            .filter((log) => selectedType === null || log.type === selectedType)
            .map((log) => (
              <TableRow key={log.id}>
                <TableCell width="200" align="center">
                  <SeverityPill
                    color={log.type !== 'acces' ? 'success' : 'warning'}
                  >
                    {log.type}
                  </SeverityPill>
                </TableCell>
                <TableCell width="400" align="center">
                  {log.description}
                </TableCell>
                <TableCell width="300" align="center">
                  {log.created_at}
                </TableCell>
                <TableCell width="150" align="center">
                  {log.courses}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </Card>
  );
};
