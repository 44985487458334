import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Avatar,
  Box,
  ButtonBase,
  Toolbar,
  ListItemIcon,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { UserCircle as UserCircleIcon } from '../../icons/user-circle';
import { AccountPopover } from './account-popover';
import SettingsIcon from '@mui/icons-material/Settings';
import { useAuth } from '../../hooks/use-auth';

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === 'light'
    ? {
      boxShadow: theme.shadows[3]
    }
    : {
      backgroundColor: theme.palette.background.paper,
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      boxShadow: 'none'
    })
}));

const AccountButton = () => {
  const {user} = useAuth()
  const anchorRef = useRef(null);
  const [openPopover, setOpenPopover] = useState(false);

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpenPopover}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          ml: 0
        }}
        
      >
        <Box
          sx={{
            alignItems: 'center',
            borderRadius: 5,
            display: 'flex',
            overflow: 'hidden',
            p: 0,
            height: 50,
            width: 100
            
          }}
          style={{ background: '#F1F1F1'}}
          // (theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : "#1c2531"
        >
          <Avatar
            sx={{
              height: 50,
              width: 50,
              mx: 0
            }}
            src={user.avatar}
          >
            <UserCircleIcon fontSize="small" />
          </Avatar>
          <ListItemIcon>
            <SettingsIcon 
              fontSize="small" 
              sx={{
                height: 30,
                width: 30,
                mx: 1.5,
                color: process.env.REACT_APP_PRIMARY_COLOR
              }}
            />
          </ListItemIcon>
        </Box>
      </Box>
      <AccountPopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
      />
    </>
  );
};

export const DashboardNavbar = (props) => {
  const { onOpenSidebar, ...other } = props;

  return (
    <>
      <DashboardNavbarRoot
        {...other}>
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
            backgroundColor: process.env.REACT_APP_PRIMARY_COLOR,
          }}
        >
           <Box
            component="img"
            sx={{
                height: 40,
                mx: 5
            }}
            alt="Your logo."
            src={process.env.REACT_APP_LOGO_NAVBAR}
        />
          <Box sx={{ flexGrow: 1 }} />
          <AccountButton/>
        </Toolbar>
      </DashboardNavbarRoot>
    </>
  );
};

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};
