class FacturationApi {
    async getLogs(email, formation_tlms_id, end_session) {
      const url = `${process.env.REACT_APP_BACK}/api/log_activity/get/`
      const values = {
        "user_id": "",
        "formation_id": "",
        "start_session": "",
        "end_session": end_session,
        "formation_tlms_id": formation_tlms_id,
        "subscribed_at": "2023-04-19 15:01:20", // Il faut juste que ça soit un format date je l'utilise pas derriere
        "email": email,
        "units" : [
            {
                "type": "sse",
                "crm": "ok"
            }
        ]
      };
      const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer 7-Ywdh!wM4@JrcYud`
        }
      });
      const json = await resp.json();
  
      return Promise.resolve(json)
    }

    async factureOnCrm(user_id, formation_id, start_session, end_session, formation_tlms_id, subscribed_at, email, units) {
      const url = `${process.env.REACT_APP_BACK}/api/log_activity/get/`
      const values = {
        "user_id": user_id,
        "formation_id": formation_id,
        "start_session": start_session,
        "end_session": end_session,
        "formation_tlms_id": formation_tlms_id,
        "subscribed_at": subscribed_at,
        "email": email,
        "units": units
      };
      const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_SYNA_APTEED_KEY}`
        }
      });
      const json = await resp.json();
      return Promise.resolve(json)
    }
  }
  
  export const facturationApi = new FacturationApi();