class AuditsApi {
    async createAudit(admin_id, name, type) {
        const url = `${process.env.REACT_APP_BACK}/api/audit/create/`
        const values = {
            "admin_id": admin_id,
            "audit_name": name,
            "type": type
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    async meanTime(admin_id) {
        const url = `${process.env.REACT_APP_BACK}/api/audit/meanTime/`
        const values = {
            "admin_id": admin_id
        }
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }
    async modifyAudit(admin_id, audit_info) {
        const url = `${process.env.REACT_APP_BACK}/api/audit/modify/`
        const values = {
            "admin_id": admin_id,
            "audit_info": audit_info
        }
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }
    async getAllEpp(admin_id) {
        const url = `${process.env.REACT_APP_BACK}/api/audit/getAllEpp/`
        const values = {
            "admin_id": admin_id,
        }
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }
}

export const auditsApi = new AuditsApi();