import { Box, Card, Fab, Grid, IconButton, TextField, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';
import { useCallback } from 'react';
import { useAuth } from '../../../hooks/use-auth';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { tcsApi } from '../../../api/tcs-api';
import { TcsVignetteModify } from './tcs-vignetteModify';
import { Add } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export const TcsModifyVignette = (props) => {
  const { setTcsInformations, tcsInformations } = props;
  const [isOpenList, setIsOpenList] = useState(new Array(tcsInformations.details.length).fill(false));
  const { user } = useAuth(); 

  const handleDeleteMiniVignette = async (id) => {
    let newInfo = {...tcsInformations}
    let id_bdd = newInfo.details.splice(id, 1)[0]["id"]
    newInfo.details = newInfo.details.map((item, index) => ({
      ...item,
      number: index + 1
    }))

    await tcsApi.deleteVignetteGroup(user.admin_id, id_bdd);
    await tcsApi.updateVignetteGroup(user.admin_id, newInfo.details)

    setTcsInformations(newInfo);
  }

  const handleAddMiniVignette = async () => {
    const newInfo = {...tcsInformations}
    const vignetteId = await tcsApi.createVignetteGroup(user.admin_id, tcsInformations.basic_info.id, tcsInformations.details.length + 1)
    newInfo.details.push(
      {
        "description": "",
        "epp_id": tcsInformations.basic_info.id,
        "more_info": null,
        "number": tcsInformations.details.length + 1,
        "id": vignetteId.id,
        "vignettes": []
      }
    )
    setTcsInformations(newInfo)
  }

  const handleItemClick = useCallback((index) => {
    const updatedIsOpenList = [...isOpenList];
    updatedIsOpenList[index] = !updatedIsOpenList[index];
    setIsOpenList(updatedIsOpenList);
  }, [isOpenList]);

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    let items = [...tcsInformations.details];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    items = items.map((item, index) => ({
      ...item,
      number: index + 1,
    }))

    setTcsInformations({
      ...tcsInformations,
      details: items
    });

    const vignetteGroup = items.map(item => {
      const { vignettes, ...rest} = item;
      return rest;
    })

    await tcsApi.updateVignetteGroup(user.admin_id, vignetteGroup)
  };

  const handleModifyVignetteGroup = async () => {
    const items = [...tcsInformations.details]
    const vignetteGroup = items.map(item => {
      const { vignettes, ...rest} = item;
      return rest;
    })
    await tcsApi.updateVignetteGroup(user.admin_id, vignetteGroup)
  }
  return (
    <>
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="tcs-details">
        {(provided) => (
          <Box {...provided.droppableProps} ref={provided.innerRef} sx={{mb: 15}}>
            {tcsInformations.details.map((item, index) => (
              <Card
              key={item.id}
              sx={{
                mx: 10,
                mt: 4,
              }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ display: "flex", py: 1}}
                >
                  <Grid item onClick={() => handleItemClick(index)} sx={{ display: "flex", alignItems: "center", width: "95%"}}>
                    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                      {(provided) => (
                        <Box
                          sx={{
                            p: 3,
                            width: '100%'
                          }}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Grid
                            container
                            onClick={() => handleItemClick(index)}
                            alignItems="center"
                          >
                            {isOpenList[index] ? (
                              <KeyboardArrowDownIcon fontSize="large" />
                            ) : (
                              <KeyboardArrowRightIcon fontSize="large" />
                            )}
                            <Typography variant="h5" sx={{ ml: "1%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                              {"Mini Vignette " + item.number}
                            </Typography>
                          </Grid>
                        </Box>
                      )}
                    </Draggable>
                  </Grid>
                  <Grid item sx={{ display: "flex", alignItems: "center", width: "5%"}}>
                    <Grid
                      container
                      alignItems={'center'}
                      justifyContent='center'
                    >
                      <IconButton
                        onClick={() => handleDeleteMiniVignette(index)}
                      >
                        <DeleteOutlineIcon/>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                {isOpenList[index] && (
                  <Grid
                    sx={{p: 3}}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography
                        color="secondary"
                        fontWeight="Bold"
                        fontSize={16}
                        display="inline"
                        maxWidth="7em"
                        flex="1"
                      >
                        Description:
                      </Typography>
                      <Box sx={{ flex: '2' }}>
                        <TextField
                          fullWidth
                          multiline
                          size='small'
                          value={item.description}
                          onChange={(e) => {
                            const newItems = [...tcsInformations.details];
                            newItems[index] = {
                              ...newItems[index],
                              description: e.target.value
                            };
                            setTcsInformations({
                              ...tcsInformations,
                              details: newItems
                            });
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault()
                              handleModifyVignetteGroup()
                            }
                          }}
                          onBlur={() => {handleModifyVignetteGroup()}}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1em'}}>
                      <Typography
                        color="secondary"
                        fontWeight="Bold"
                        fontSize={16}
                        display="inline"
                        flex="1"
                        maxWidth="13em"
                      >
                        Plus d'info sur la page n°
                      </Typography>
                      <Box sx={{ flex: '2', display: 'flex', alignItems: 'center'}}>
                        <TextField
                          sx={{ width: '5em', marginRight: '1em'}}
                          size='small'
                          type='number'
                          value={item.more_info}
                          onChange={(e) => {
                            const newItems = [...tcsInformations.details];
                            newItems[index] = {
                              ...newItems[index],
                              more_info: e.target.value
                            };
                            setTcsInformations({
                              ...tcsInformations,
                              details: newItems
                            });
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault()
                              handleModifyVignetteGroup()
                            }
                          }}
                          onBlur={() => {handleModifyVignetteGroup()}}
                        />
                        <Typography
                          color="secondary"
                          fontWeight="Bold"
                          fontSize={16}
                          display="inline"
                        >
                          du pdf
                        </Typography>
                      </Box>
                    </Box>
                    <TcsVignetteModify
                      tcsVignette={item.vignettes}
                      index={index}
                      tcsInformations={tcsInformations}
                      setTcsInformations={setTcsInformations}
                    />
                  </Grid>
                )}
              </Card>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
    <Fab
    sx={{
      top: 'auto',
      right: 50,
      bottom: 30,
      left: 'auto',
      position: 'fixed',
      boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 6px 7px -3px",
    }}
    size="large"
    variant="extended"
    color="primary"
    aria-label="add"
    onClick={() => handleAddMiniVignette()}
  >
    <Add />
    Ajouter une Mini Vignette
  </Fab>
  </>
  );  
};