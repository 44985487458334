class TopicsApi {
    async getAll(admin_id) {
        const url = `${process.env.REACT_APP_BACK}/api/topic/getAll/`
        const values = {
            "admin_id": admin_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    async getPostsByTopic(admin_id, topics_id) {
        const url = `${process.env.REACT_APP_BACK}/api/topic/getPosts/`
        const values = {
            "admin_id": admin_id,
            "topics_id": topics_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    async getRepliesByPost(admin_id, posts_id) {
        const url = `${process.env.REACT_APP_BACK}/api/topic/getReplies/`
        const values = {
            "admin_id": admin_id,
            "posts_id": posts_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    async getProgression(admin_id) {
        const url = `${process.env.REACT_APP_BACK}/api/session/progression/`
        const values = {
            "admin_id": admin_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }
}

export const topicsApi = new TopicsApi();