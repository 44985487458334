import ReactDOM from 'react-dom'
import { StrictMode } from 'react'
import App from './pages/_app'
import { BrowserRouter } from 'react-router-dom'

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </StrictMode>, document.getElementById('root')
)