import { useCallback, useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useAuth } from '../../../hooks/use-auth';
import { useMounted } from '../../../hooks/use-mounted';
import { FormationsList } from '../../../components/dashboard/formations/formations-list';
import { gtm } from '../../../lib/gtm';
import { formationsApi } from '../../../api/formation-api';


const Formations = () => {
  const isMounted = useMounted();
  const [formations, setFormations] = useState([])
  const { user } = useAuth(); 

  const getFormations = useCallback(async () => {
    try {
      const data = await formationsApi.getAllFormations(user.admin_id)
      if (isMounted()) {
        setFormations(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    getFormations();
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 11
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ mb: 5 }}>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item>
                <Typography variant="h4">
                  Formations
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                xs={12}
              >
                <FormationsList
                  formations = {formations}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};


export default Formations;
