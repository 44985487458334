import { useEffect } from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { SessionCreate } from '../../../components/dashboard/session/create-session';
import { gtm } from '../../../lib/gtm';
import { Link as RouterLink, useLocation} from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const CreateSession = () => {
  const location = useLocation();
  
  const { from, formationId } = location.state;

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
          
        }}
      >
        <Container maxWidth="x1">
          <Box sx={{ mb: 4}}>
            <RouterLink
              to={from}
              style={{ textDecoration: 'none' }}
            >
              <Link
                color="textPrimary"
                component="a"
                sx={{
                  alignItems: 'center',
                  display: 'flex'
                }}
              >
                <ArrowBackIcon
                  fontSize="small"
                  sx={{ mr: 1 }}
                />
                <Typography variant="subtitle2">
                  {formationId === -1 ? "Liste des sessions" : "Formation"}
                </Typography>
              </Link>
            </RouterLink>
          </Box>
          <Box
            component="main"
            sx={{
            flexGrow: 1,
            }}
          >
              <Box sx={{ mb: 4 }}>
                <Grid
                container
                justifyContent="space-between"
                spacing={3}
                >
                  <Grid item>
                    <Typography variant="h4">
                    Créer une session
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <SessionCreate
                formationId={formationId}
              />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CreateSession;
