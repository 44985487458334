class FormationsApi {
    async getAllFormations(admin_id) {
        const url = `${process.env.REACT_APP_BACK}/api/formation/getAll/`
        const values = {
            "admin_id": admin_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json)
    }
    
    async createFormation(admin_id, name) {
        const url = `${process.env.REACT_APP_BACK}/api/formation/create/`
        const values = {
            "admin_id": admin_id,
            "name": name
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    
    async getFormationData(admin_id, formationId) {
        const url = `${process.env.REACT_APP_BACK}/api/formation/getData/`
        const values = {
            "admin_id": admin_id,
            "id": formationId
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };

    async getAllInformationsByAuditId(admin_id, course_id) {
        const url = `${process.env.REACT_APP_BACK}/api/formation/getDataByAuditId/`
        const values = {
            "admin_id": admin_id,
            "course_id": course_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };

    async modifyFormation(admin_id, formation) {
        const url = `${process.env.REACT_APP_BACK}/api/formation/modify/`
        const values = {
            "admin_id": admin_id,
            "formation_id": formation.id,
            "audits_id": formation.audits_id,
            "name": formation.name,
            "program_number": formation.program_number,
            "tlms_id": formation.tlms_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async getSessionsByFormationsId(admin_id, formations_id) {
        const url = `${process.env.REACT_APP_BACK}/api/formation/getSessions/`
        const values = {
            "admin_id": admin_id,
            "formations_id": formations_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async getAllUnitsSyna(admin_id) {
        const url = `${process.env.REACT_APP_BACK}/api/syna/getAllUnits/`
        const values = {
            "admin_id": admin_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }

    async saveUnitsFormation(admin_id, formation_id, units_syna_formation_id) {
        const url = `${process.env.REACT_APP_BACK}/api/formation/saveUnits/`
        const values = {
            "admin_id": admin_id,
            "formation_id": formation_id,
            "units_syna_formation_id": units_syna_formation_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    }
}

export const formationsApi = new FormationsApi();