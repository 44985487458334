class UserApi {
  async getUsers(admin_id) {
    const url = `${process.env.REACT_APP_BACK}/api/users_all/get/`
    const values = {
      "admin_id": admin_id
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json)
  }
  
  async getUsersStats(admin_id, customer_id) {
    const url = `${process.env.REACT_APP_BACK}/api/users_stats/get/`
    const values = {
      "admin_id": admin_id,
      "customer_id": customer_id
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json)
  }

  async getUsersActivities(admin_id, customer_id) {
    const url = `${process.env.REACT_APP_BACK}/api/users_activities/get/`
    const values = {
      "admin_id": admin_id,
      "customer_id": customer_id
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json)
  }

  async getLastActivities(admin_id) {
    const url = `${process.env.REACT_APP_BACK}/api/activities/getLast`
    const values = {
      "admin_id": admin_id
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json)
  }
  
  async getEppByType(admin_id, type) {
    const url = `${process.env.REACT_APP_BACK}/api/audits_all/get/`
    const values = {
      "admin_id": admin_id,
      "type": type
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json)
  }
  async createUser(admin_id, data) {
    const url = `${process.env.REACT_APP_BACK}/api/create_user/`
    const values = {
      "admin_id": admin_id,
      "firstname": data.firstname,
      "lastname": data.lastname,
      "email": data.email,
      "tlms_id": data.tlms_id,
      "syna_id": data.syna_id
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json)
  }
  async sendIds(admin_id, users_id) {
    const url = `${process.env.REACT_APP_BACK}/api/credentials/crm/sent/`
    const values = {
      "admin_id": admin_id,
      "users_id": users_id
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }
  async modifyUser(admin_id, user) {
    const url = `${process.env.REACT_APP_BACK}/api/user/modify/`
    const values = {
      "admin_id": admin_id,
      "user_id": user.details.id,
      "firstname": user.details.firstname,
      "lastname": user.details.lastname,
      "email": user.details.email,
      "tlms_id": user.details.tlms_id,
      "syna_id": user.details.syna_id,
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }
}

export const userApi = new UserApi();