import { 
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  useMediaQuery,
  Typography,
  TextField,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom'
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import ProgressBar from '../session/progress-bar'
import { useEffect, useState } from 'react';
import { coursesApi } from '../../../api/course-api';
import { useAuth } from '../../../hooks/use-auth';
import { ExportMenu } from '../../export-menu';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CustomerResponseAudit } from './customer-response-audit.js';
import dayjs from 'dayjs';
import Swal from 'sweetalert2'

export const CustomerCoursesGrid = (props) => {
  const { customer, setCustomer, index, customer_id } = props;
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const [open, setOpen] = useState(false);
  const { user } = useAuth(); 
  
  const [sessionDate, setSessionDate] = useState({
    "start_session": customer.audits[index].start_session,
    "end_session": customer.audits[index].end_session
  })

  const [modify, setModify] = useState({
    "start_session": null,
    "end_session": null,
  });

  async function handleModify(field, new_date) {
    setModify(modify => ({
      ...modify,
      [field]: false
    }));
    let changeDate = {...sessionDate};
    changeDate[field] = new_date;
    setSessionDate(changeDate);
    await coursesApi.changeDate(user.admin_id, customer.audits[index].course_id, changeDate.start_session, changeDate.end_session)
  }

  const align = mdUp ? 'horizontal' : 'vertical';

  async function handleDesactivate() {
    let items = [...customer.audits];
    items[index].activated = false;
    await coursesApi.desactivateCourse(user.admin_id, customer.audits[index].audit_id, customer_id, sessionDate.start_session, sessionDate.end_session)
    setCustomer({
      ...customer,
      audits: items
    });
  }

  async function handleActivate() {
    let items = [...customer.audits];
    items[index].activated = true;
    await coursesApi.activateCourse(user.admin_id, customer.audits[index].audit_id, customer_id, sessionDate.start_session, sessionDate.end_session)
    setCustomer({
      ...customer,
      audits: items
    });
  }
  
  async function handleUnlockSecondTurn() {
    Swal.fire({
      title: 'Voulez-vous vraiment débloquer le second tour?',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let answer = await coursesApi.unlockSecondTurn(user.admin_id, customer.audits[index].course_id)
        if (answer.msg == 'unlocked') {
          Swal.fire({
            title: "Tour débloqué",
            icon: 'success',
          })
        } else if (answer.msg == 'impossible unlock') {
          Swal.fire({
            title: "Impossible de débloquer le tour maintenant",
            icon: 'warning',
          })
        } else if (answer.msg == 'already unlock') {
          Swal.fire({
            title: "Second tour déjà débloqué",
            icon: 'error',
          })
        }
      }
    })
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card
      sx={{
        backgroundColor:(customer.audits[index].activated ? "#FDFDFD" : "#CBCBCB")
      }}
    >
      <CardHeader
        action={customer.audits[index].sessions_id ? <ExportMenu
          course_id={customer.audits[index].course_id}
        />
        : ""
        }
        title={
          <RouterLink
            to={`/dashboard/session/1`}
            state={{
              data_from: {
                auditId: customer.audits[index].audit_id,
                startSession: sessionDate.start_session,
                endSession: sessionDate.end_session,
                sessions_id: customer.audits[index].sessions_id,
                from: "/dashboard/customers/" + customer_id
              }
            }}
            style={{ textDecoration: 'none', color:process.env.REACT_APP_PRIMARY_COLOR}}
          >
            {customer.audits[index].audit_name}
            <Typography
              variant="h6"
              color="textSecondary"
            >
              Id: {customer.audits[index].course_id}
            </Typography>
            {customer.audits[index].sessions_id ?
              <Typography
                variant='h6'
              >
                Session n° {customer.audits[index].session_number}
              </Typography>
              :
              ""
            }
            <Typography
              variant="h5"
            >
              {customer.audits[index].activated ? "" : "(Désactivé)"}
            </Typography>
          </RouterLink>
        }
        titleTypographyProps={{variant:'h3' }}
        style={{ textAlign: "center"}}
      />
      <PropertyList>
        <PropertyListItem
          align={align}
          divider
          label="Type de formation"
          value={customer.audits[index].action_type ? customer.audits[index].action_type.toString() : 'Non renseigné'}
        />
        <PropertyListItem
          align={align}
          divider
          label="Format"
          value={customer.audits[index].format ? customer.audits[index].format.toString() : 'Non renseigné'}
        />
        <PropertyListItem
          align={align}
          divider
          label="Numéro de programme"
          value={customer.audits[index].program_number ? customer.audits[index].program_number.toString() : 'Non renseigné'}
        />
        <PropertyListItem
          align={align}
          divider
          label="Nombre de patients tour 1"
          value={customer.audits[index].nb_patients1.toString()}
        />
        <PropertyListItem
          align={align}
          divider
          label="Nombre de patients tour 2"
          value={customer.audits[index].nb_patients2.toString()}
        />
        <PropertyListItem
          align={align}
          divider
          label="Début de session"
          value={
            modify.start_session?
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale='frFR'
            >
              <DatePicker
                label="Date de début de session"
                showTodayButton
                focused
                autoFocus
                clearable
                inputFormat='DD/MM/YYYY'
                dateFormat='DD/MM/YYYY'
                InputAdornmentProps={{ position: 'start', variant: 'standard' }}
                onChange={(date) => {
                  if (dayjs(date).isValid()) {
                    const value = dayjs(date, 'MM/DD/YYYY').format('DD/MM/YYYY');
                    handleModify("start_session", value);
                  }
                }}
                renderInput={(params) =>
                  <TextField
                  {...params}
                  />
                }
                value={dayjs(sessionDate.start_session, 'DD/MM/YYYY').format('MM/DD/YYYY')}
              />
            </LocalizationProvider>
            : //ELSE
            <Typography
              onDoubleClick={() => {
                setModify(modify => ({
                ...modify,
                start_session: true
                }))
              }}
              color="textSecondary"
              variant="body2"
            >
              {sessionDate.start_session}
            </Typography>
          }
        />
        <PropertyListItem
          align={align}
          divider
          label="Fin de session"
          value={
            modify.end_session?
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale='frFR'
            >
              <DatePicker
                label="Date de fin de session"
                showTodayButton
                focused
                autoFocus
                clearable
                inputFormat='DD/MM/YYYY'
                dateFormat='DD/MM/YYYY'
                InputAdornmentProps={{ position: 'start', variant: 'standard' }}
                onChange={(date) => {
                  if (dayjs(date).isValid()) {
                    const value = dayjs(date, 'MM/DD/YYYY').format('DD/MM/YYYY');
                    handleModify("end_session", value);
                  }
                }}
                renderInput={(params) =>
                  <TextField
                  {...params}
                  />
                }
                value={dayjs(sessionDate.end_session, 'DD/MM/YYYY').format('MM/DD/YYYY')}
              />
            </LocalizationProvider>
            : //ELSE
            <Typography
              onDoubleClick={() => {
                setModify(modify => ({
                ...modify,
                end_session: true
                }))
              }}
              color="textSecondary"
              variant="body2"
            >
              {sessionDate.end_session}
            </Typography>
          }
        />
        <PropertyListItem
          align={align}
          divider
          setMaxWidth={150}
          label="Progression"
          value={<ProgressBar
            value={customer.audits[index].progression}
          />}
        />
        <PropertyListItem
          align={align}
          divider
          label="Étape"
          value={`${customer.audits[index].step}`}
        />
        <PropertyListItem
          align={align}
          divider
          label="État"
          value={`${customer.audits[index].state}`}
        />
        <PropertyListItem
          align={align}
          divider
          label="Nombre d'heures passées"
          value={customer.audits[index].spent_time}
        />
        <PropertyListItem
          align={align}
          divider
          label="Prédiction fin"
          value={customer.audits[index].prediction}
        />
      </PropertyList>
      <Grid
        display="flex"
        margin={1}
      >
        <Grid
          marginRight={'1%'}
        >
          <Button
            variant="contained"
            onClick={handleClickOpen}
            >
            Voir les réponses
          </Button>
        </Grid>
        <Grid>
          {customer.audits[index].activated ?
            <Button
              variant="contained"
              onClick={handleDesactivate}
            >
              Désactiver
            </Button>
            :
            <Button
              variant="contained"
              onClick={handleActivate}
            >
              Réactiver
            </Button>
          }
        </Grid>
        <Grid
          marginLeft={'auto'}
        >
          {user.super_admin?
          <Button
            onClick={handleUnlockSecondTurn}
            variant="contained"
          >
            Débloquer le second tour
          </Button>
          : ""
          }
        </Grid>
        <Box
          margin={1}
        >
          <CustomerResponseAudit
            open={open}
            handleClose={handleClose}
            audit_id={customer.audits[index].audit_id}
            customer_id={customer_id}
            start_session={customer.audits[index].start_session}
            end_session={customer.audits[index].end_session}
          />
        </Box>
        </Grid>
    </Card>
  );
};
