import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, Button, FormHelperText, TextField } from '@mui/material';
import { useAuth } from '../../hooks/use-auth';
import { useMounted } from '../../hooks/use-mounted';
import toast from 'react-hot-toast';

export const JWTLogin = (props) => {
  const isMounted = useMounted();
  const navigate = useNavigate();
  const { login } = useAuth();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      submit: null
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email('L\'adresse mail doit être valide')
        .max(255)
        .required('Adresse mail requise'),
      password: Yup
        .string()
        .max(255)
        .required('Mot de passe requis')
    }),
    onSubmit: async (values, helpers) => {
      try {
        const res = await login(values.email, values.password);

        if (isMounted() && res == true) {
          navigate('/dashboard', { replace: true});
        } else {
          toast.error('Email ou mot de passe incorrect(s)');
        }
      } catch (err) {
        console.error(err);
        if (isMounted()) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }
    }
  });

  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}
      {...props}>
      <TextField
        autoFocus
        error={Boolean(formik.touched.email && formik.errors.email)}
        fullWidth
        helperText={formik.touched.email && formik.errors.email}
        label="Adresse mail"
        margin="normal"
        name="email"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="email"
        value={formik.values.email}
        sx={{ mt: 5, p: 2}}
      />
      <TextField
        error={Boolean(formik.touched.password && formik.errors.password)}
        fullWidth
        helperText={formik.touched.password && formik.errors.password}
        label="Mot de passe"
        margin="normal"
        name="password"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="password"
        value={formik.values.password}
        sx={{ mt: 4, p: 2}}
      />
      {formik.errors.submit && (
        <Box sx={{ mt: 3 }}>
          <FormHelperText error>
            {formik.errors.submit}
          </FormHelperText>
        </Box>
      )}
      <Box sx={{ mt: 0, p: 2 }}>
        <Button
          disabled={formik.isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ p: 2}}
        >
          Connexion
        </Button>
      </Box>
    </form>
  );
};
