import AuthGuard from '../components/authentication/auth-guard.js';
import { DashboardLayout } from '../components/dashboard/dashboard-layout.js';

import Login from './login_page/index.js'

import Dashboard from '../pages/dashboard/index.js';
import Customers from '../pages/dashboard/customers/index.js'
import Audits from '../pages/dashboard/audits/index.js'
import Tcs from '../pages/dashboard/tcs/index.js'
import Errors from '../pages/dashboard/errors.js'
import CreateUser from '../pages/dashboard/create/index.js'
import CustomerDetails from '../pages/dashboard/customers/[customerId].js'
import AuditsGraphicsDetails from '../pages/dashboard/audits/[auditId].js'
import CourseSession from '../pages/dashboard/session/[session].js'
import CreateSession from '../pages/dashboard/session/create_session.js'
import Forum from '../pages/dashboard/forum/index.js';
import SessionHome from '../pages/dashboard/session/home.js';
import Formations from '../pages/dashboard/formations/index.js'
import FormationsDetails from '../pages/dashboard/formations/[formationId].js';
import TcsInformationsById from '../pages/dashboard/tcs/[tcs].js';

const routes = [
  {
    path: '/',
    element: <Login/>
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout/>
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Dashboard />
      },
      {
        path: 'customers',
        children: [
          {
            path: '',
            element: <Customers />
          },
          {
            path: ':customerId',
            element: <CustomerDetails />,
          }
        ]
      },
      {
        path: 'audits',
        children: [
          {
            path: '',
            element: <Audits />
          },
          {
            path: ':auditId',
            element: <AuditsGraphicsDetails />
          },
        ]
      },
      {
        path: 'tcs',
        children: [
          {
            path: '',
            element: <Tcs/>
          },
          {
            path: ':tcsId',
            element: <TcsInformationsById />
          },
        ]
      },
      {
        path: 'formations',
        children: [
          {
            path: '',
            element: <Formations />
          },
          {
            path: ':formationId',
            element: <FormationsDetails />
          }
        ]
      },
      {
        path: 'session',
        children: [
          {
            path: '',
            element: <SessionHome />
          },
          {
            path: ':sessionId',
            element: <CourseSession />
          },
          {
            path: 'create',
            element: <CreateSession />
          }
        ]
      },
      {
        path: 'errors',
        element: <Errors />
      },
      {
        path: 'create',
        element: <CreateUser /> 
      },
      {
        path: 'forum',
        element: <Forum />
      }
    ]
  }
];

export default routes;