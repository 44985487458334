import PropTypes from 'prop-types';
import { Box, Link, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import { useState } from 'react';

export const PropertyListItem = (props) => {
  const { align, children, disableGutters, value, label, linked_text, setMaxWidth, ...other } = props;

  const [modify, setModify] = useState(false);

  return (
    <ListItem
      sx={{
        px: disableGutters ? 0 : 3,
        py: 1.5
      }}
      {...other}>
      <ListItemText
        disableTypography
        primary={(
          (linked_text && !value.includes("Pas")? 
          <Link
            href={linked_text}
            target="_blank"
          >
            <Typography
              sx={{ minWidth: align === 'vertical' ? 'inherit' : 180 }}
              variant="subtitle2"
            >
              {label}
            </Typography>
          </Link>
          : // ELSE
          <Typography
            sx={{ minWidth: align === 'vertical' ? 'inherit' : 180 }}
            variant="subtitle2"
          >
            {label}
          </Typography>
          )
        )}
        secondary={(
          <Box
            sx={{
              flex: 1,
              mt: align === 'vertical' ? 0.5 : 0,
              // display: "flex",
              display: setMaxWidth ? "" : "flex",
              maxWidth: setMaxWidth ? setMaxWidth : 1000
            }}
          >
            {children || (
              (linked_text && !value.includes("Pas")?
                <Link
                  href={linked_text}
                  target="_blank"
                >
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {value}
                  </Typography>
                </Link>
                : // ELSE
                modify && (label === "Tlms" || label === "Syna")?
                  <TextField
                    size='small'
                    focused
                    autoFocus
                    placeholder={"Entrez l'id " + label}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setModify(false)
                      }
                    }}
                    onBlur={() => {setModify(false)}}
                    onChange={(event) => {
                      setModifyCriteria(event.target.value)
                    }}
                  />
                  : //ELSE
                  <Typography
                  onDoubleClick={() => setModify(true)}
                  color="textSecondary"
                  variant="body2"
                  >
                  {value}
                  </Typography>
                
              )
            )}
          </Box>
        )}
        sx={{
          display: 'flex',
          flexDirection: align === 'vertical' ? 'column' : 'row',
          my: 0
        }}
      />
    </ListItem>
  );
};

PropertyListItem.defaultProps = {
  align: 'vertical'
};

PropertyListItem.propTypes = {
  align: PropTypes.oneOf(['horizontal', 'vertical']),
  children: PropTypes.node,
  disableGutters: PropTypes.bool,
  label: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
  // value: PropTypes.string
};
