import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../../hooks/use-auth';

import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import { errorsApi } from '../../../api/error-api';
import { useMounted } from '../../../hooks/use-mounted';
import { MoreMenu } from '../../more-menu';
import { Scrollbar } from '../../scrollbar';
import { SeverityPill } from '../../severity-pill';

export const ErrorsLink = () => {
  const isMounted = useMounted();
  const [errors, setErrors] = useState([]);
  const { user } = useAuth(); 
  const getInvoices = useCallback(async () => {
    try {
      const data = await errorsApi.getLinksErrors(user.admin_id);

      if (isMounted()) {
        setErrors(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    getInvoices();
  }, [getInvoices]);

  return (
    <Card>
      <CardHeader
        title="Cours associé à un utilisateur non existant"
      />
      <Divider />
      <Scrollbar>
        <Table sx={{ minWidth: 600 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                Id de l&apos;utilisateur
              </TableCell>
              <TableCell>
                Id du cours
              </TableCell>
              <TableCell>
                Id de l&apos;audit
              </TableCell>
              <TableCell>
                Debut de session
              </TableCell>
              <TableCell>
                Fin de session
              </TableCell>
              <TableCell>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {errors? errors.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  {item.users}
                </TableCell>
                <TableCell>
                  {item.id}
                </TableCell>
                <TableCell>
                  {item.audit}
                </TableCell>
                <TableCell>
                  {item.start_session}
                </TableCell>
                <TableCell>
                  {item.end_session}
                </TableCell>
                <TableCell>                  
                  <SeverityPill color='error'>
                    Error
                  </SeverityPill>
                </TableCell>
              </TableRow>
            )) : NULL }
          </TableBody>
        </Table>
      </Scrollbar>
      <TablePagination
        component="div"
        count={errors.length}
        onPageChange={() => {
        }}
        onRowsPerPageChange={() => {
        }}
        page={0}
        labelRowsPerPage='Lignes par page :'
        rowsPerPage={5}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};
