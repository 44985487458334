import { useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../../hooks/use-auth';
import { Navigate, useLocation } from 'react-router-dom'
import Login from '../../modules/login_page/index';

const AuthGuard = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation()
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return <Login />;
  }
    if (requestedLocation && location.pathname !== requestedLocation) {
      setRequestedLocation(null);
      return <Navigate to={requestedLocation} />;
    }
  return <>{children}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard
