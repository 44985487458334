import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../hooks/use-auth';
import { gtm } from '../../lib/gtm';

import {
  Box,
  Card,
  Container,
  Divider,
  Grid,
  Typography
} from '@mui/material';

import { useMounted } from '../../hooks/use-mounted';
import { topicsApi } from '../../api/topic-api';
import { auditsApi } from '../../api/audit-api';

import { AnalyticsGeneralOverview } from '../../components/dashboard/analytics/analytics-general-overview';
import { AvancementSessions } from '../../components/dashboard/statistiques/avancementSessions';
import { FinanceCostBreakdown } from '../../components/dashboard/finance/finance-cost-breakdown';
import { FinanceIncrementalSales } from '../../components/dashboard/finance/finance-incremental-sales';
import { FinanceOverview } from '../../components/dashboard/finance/finance-overview';
import { FinanceProfitableProducts } from '../../components/dashboard/finance/finance-profitable-products';
import { FinanceSalesByContinent } from '../../components/dashboard/finance/finance-sales-by-continent';
import { FinanceSalesRevenue } from '../../components/dashboard/finance/finance-sales-revenue';

import { MeanByAudit } from '../../components/dashboard/statistiques/mean-audit';
import { LastActivities } from '../../components/dashboard/statistiques/last-activities';

import { Chart2 } from '../../components/widgets/charts/chart-2';
import { Chart3 } from '../../components/widgets/charts/chart-3';
import { Chart4 } from '../../components/widgets/charts/chart-4';
import { Chart5 } from '../../components/widgets/charts/chart-5';
import { Chart6 } from '../../components/widgets/charts/chart-6';
import { Chart7 } from '../../components/widgets/charts/chart-7';
import { Chart8 } from '../../components/widgets/charts/chart-8';
import { Chart9 } from '../../components/widgets/charts/chart-9';
import { Chart10 } from '../../components/widgets/charts/chart-10';
import { Chart11 } from '../../components/widgets/charts/chart-11';
import { Chart12 } from '../../components/widgets/charts/chart-12';

import { QuickStats1 } from '../../components/widgets/quick-stats/quick-stats-1';
import { QuickStats2 } from '../../components/widgets/quick-stats/quick-stats-2';
import { QuickStats3 } from '../../components/widgets/quick-stats/quick-stats-3';
import { QuickStats4 } from '../../components/widgets/quick-stats/quick-stats-4';
import { QuickStats5 } from '../../components/widgets/quick-stats/quick-stats-5';
import { QuickStats6 } from '../../components/widgets/quick-stats/quick-stats-6';
import { QuickStats7 } from '../../components/widgets/quick-stats/quick-stats-7';
import { QuickStats8 } from '../../components/widgets/quick-stats/quick-stats-8';

const Dashboard = () => {
  const isMounted = useMounted();
  const [progression, setProgression] = useState([])
  const [meanTime, setMeanTime] = useState([])

  const { user } = useAuth(); 

  const getMeanTime = useCallback(async () => {
    try {
      const data = await auditsApi.meanTime(user.admin_id)
      if (isMounted()) {
        setMeanTime(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const getProgression = useCallback(async () => {
    try {
      const data = await topicsApi.getProgression(user.admin_id)
      if (isMounted()) {
        setProgression(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    getProgression();
    getMeanTime();
    gtm.push({ event: 'page_view' });
  },
  []);


  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 11,
          
        }}
      >
        <Container maxWidth="x1">
          <Box sx={{ mb: 5 }}>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item>
                <Typography 
                  sx={{
                    color:(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : "#1c2531"
                  }}
                  variant="h4"
                >
                  Tableau de bord
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Grid container 
            spacing={2}
          >
            <Grid item 
              sm={12}
              md={12}
              lg={6}
            >
              <AvancementSessions 
                progression={progression}
              />
            </Grid>
            <Grid item 
              sm={12}
              md={12} 
              lg={6}
            >
              <LastActivities />
            </Grid>
          </Grid>
          <Box
            marginTop={5}
          >
            <MeanByAudit
              time={meanTime}
              />
          </Box>
          {/* <AnalyticsGeneralOverview/> */}
          {/* <FinanceSalesByContinent/> */}
          {/* <FinanceCostBreakdown/> */}
          {/* <FinanceIncrementalSales/> */}
          {/* <FinanceOverview/> */}
          {/* <FinanceProfitableProducts/> */}
          {/* <FinanceSalesRevenue/> */}
          {/* <Chart2/>
          <Chart3/>
          <Chart4/>
          <Chart5/>
          <Chart6/>
          <Chart7/>
          <Chart8/>
          <Chart9/>
          <Chart10/>
          <Chart11/>
          <Chart12/>

          <QuickStats1/>
          <QuickStats2/>
          <QuickStats3/>
          <QuickStats4/>
          <QuickStats5/>
          <QuickStats6/>
          <QuickStats7/>
          <QuickStats8/> */}
        </Container>
    </Box>
  </>
);
};

export default Dashboard;
