import { useCallback, useEffect, useRef, useState } from 'react';
import { ListItemIcon, ListItemText, Tooltip, IconButton, Menu, MenuItem } from '@mui/material';
import { DocumentText as DocumentTextIcon } from '../icons/document-text';
import { DotsHorizontal as DotsHorizontalIcon } from '../icons/dots-horizontal';
import * as XLSX from 'xlsx';
import { formationsApi } from '../api/formation-api';
import { useMounted } from '../hooks/use-mounted';
import { useAuth } from '../hooks/use-auth';
import { facturationApi } from '../api/facturation-api';
import toast from 'react-hot-toast';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';

export const ExportMenu = (props) => {
  const { course_id } = props;
  const anchorRef = useRef(null);
  const [openExport, setOpenExport] = useState(false);
  const [formation, setFormation] = useState(null)
  const [loading, setLoading] = useState(false);

  const { user } = useAuth(); 
  const isMounted = useMounted();

  const getFormation = useCallback(async () => {
    try {
      const data = await formationsApi.getAllInformationsByAuditId(user.admin_id, course_id)
      if (isMounted()) {
        setFormation(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const handleExportOpen = () => {
    setOpenExport(true);
  };

  const handleMenuClose = () => {
    setOpenExport(false);
  };

  const handleLogs = async () => {
    try {
      setLoading(true);
      const units = await facturationApi.factureOnCrm(formation.syna_id, formation.formation_id, formation.start_session, formation.end_session, formation.tlms_id, formation.subscribed_at, formation.email, formation.units);
      const tableExcel = [];

      units.forEach((element) => {
        tableExcel.push(['Nom', 'Prénom', 'Email du participant', 'ID de la session', 'Nom du module', 'Connexion - Date et heure', 'Déconnexion - Date et heure', 'Temps passé', 'Progression (%)']);
        element.codeco.forEach((cdc) => tableExcel.push([`${formation.lastname}`, `${formation.firstname}`, `${formation.email}`, `${formation.program_number}`, `${cdc.module_name}`, `${cdc.connect}`, `${cdc.disconnect}`, `${cdc.duration}`, `${cdc.progression}`]));
        tableExcel.push(['', '', '', '', `${element.expected_time}`, '', '', `${element.total_time}`]);
        tableExcel.push([]);
      });
      const formattedDate = moment(formation.subscribed_at).format("DD/MM/YYYY");
      const formattedTime = moment(formation.subscribed_at).format("HH:mm:ss");
      tableExcel.push(["Date d'inscription", `${formattedDate} à ${formattedTime}`]);
      const worksheet = XLSX.utils.aoa_to_sheet(
        tableExcel
      );

      const colNum = XLSX.utils.decode_col('H');
      const fmt = 'h:mm:ss';

      const range = XLSX.utils.decode_range(worksheet['!ref']);
      for (let i = range.s.r + 1; i <= range.e.r; ++i) {
        const ref = XLSX.utils.encode_cell({ r: i, c: colNum });
        if (worksheet[ref] && worksheet[ref].v.includes(':')) {
          worksheet[ref].z = fmt;
        }
      }

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, worksheet, 'Logs');

      /* generate XLSX file and send to client */
      XLSX.writeFile(wb, `User ${formation.lastname} ${formation.firstname}.xlsx`);
      setLoading(false);
      setOpenExport(false);
    }
    catch (err) {
      console.error(err);
      toast.error('Il y a eu un souci lors de la génération de l\'attestation !');
      setLoading(false);
      setOpenExport(false);
    }
  };

  useEffect(() => {
    getFormation();
  }, []);


  return (
    <>
      {loading?
        <CircularProgress />
      :
      <>
        <Tooltip title="More options">
          <IconButton
            onClick={handleExportOpen}
            ref={anchorRef}
            {...props}>
            <DotsHorizontalIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorRef.current}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'top'
          }}
          onClose={handleMenuClose}
          open={openExport}
          PaperProps={{
            sx: {
              maxWidth: '100%',
              width: 256
            }
          }}
          transformOrigin={{
            horizontal: 'left',
            vertical: 'top'
          }}
        >
          <MenuItem onClick={handleLogs}>
              <ListItemIcon>
                  <DocumentTextIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText 
                  primary="Exporter la traçabilité"
              />
          </MenuItem>
        </Menu>
      </>
      }
    </>
  );
};
