import { Button, Checkbox, FormControlLabel, Grid, IconButton, Radio, TextField} from '@mui/material';
import { tcsApi } from '../../../api/tcs-api';
import { useAuth } from '../../../hooks/use-auth';
import { Add, DeleteOutline } from '@mui/icons-material';

export const TcsRulesModify = (props) => {
  const { tcsInformations, setTcsInformations, indexVignette, index } = props;
  const { user } = useAuth(); 

  const handleDeleteRule = async (id) => {
    let newInfo = {...tcsInformations}
    let id_bdd = newInfo.details[index].vignettes[indexVignette].vignette_rules.splice(id, 1)[0]["id"]
    newInfo.details[index].vignettes[indexVignette].vignette_rules.map((item, index) => ({
      ...item,
      rank: index + 1
    }))
    
    await tcsApi.deleteVignetteRule(user.admin_id, id_bdd)
    setTcsInformations(newInfo)
  }

  const handleAddRules = async () => {
    const newInfo = {...tcsInformations}
    const vignetteRuleId = await tcsApi.createVignetteRule(user.admin_id, tcsInformations.details[index].vignettes[indexVignette].vignette_rules.length + 1, tcsInformations.details[index].vignettes[indexVignette].id)
    newInfo.details[index].vignettes[indexVignette].vignette_rules.push(
      {
        "first_rule": "",
        "good_answer": 0,
        "id": vignetteRuleId.id,
        "rank": tcsInformations.details[index].vignettes[indexVignette].vignette_rules.length + 1,
        "second_rule": "",
        "vignette_id": tcsInformations.details[index].vignettes[indexVignette].id
      }
    )
    setTcsInformations(newInfo)
  }

  const handleChangeTcsInformations = (field, value, indexRules) => {
    const newDetails = [...tcsInformations.details];
    newDetails[index].vignettes[indexVignette].vignette_rules[indexRules][field] = value;
    setTcsInformations({ ...tcsInformations, details: newDetails });
  };

  const sendVignetteRulesChangeToDb = async (indexRules) => {
    const dataVignetteRulesAll = tcsInformations.details[index].vignettes[indexVignette].vignette_rules[indexRules]
    const dataVignetteRules = (({vignette_id, ...rest}) => rest)(dataVignetteRulesAll)
    await tcsApi.updateVignetteRules(user.admin_id, dataVignetteRules)
  }

  return (
    <Grid container display="flex">
      <Grid item sx={{ width: '2em', display:'flex', flexDirection:'column', justifyContent: 'flex-end', alignItems: 'center'}}> 
        <IconButton
          size='small'
          variant="outlined"
          color="primary"
          sx={{border: 1, borderRadius: '50%'}}
          onClick={handleAddRules}
        >
          <Add />
          </IconButton>
      </Grid>
      <Grid item sx={{flexGrow: 1, flexBasis:'auto'}}>
        {tcsInformations.details[index].vignettes[indexVignette].vignette_rules.map((row, indexRules) => (
          <Grid key={`${indexRules}`} container
            sx={{
                backgroundColor: indexRules % 2 !== 0 ? 'white' : '#DCDCDC',
            }}
          >
            <Grid item sm={4}
              sx={{
                  borderBottom: '2px solid grey',
                  borderLeft: '2px solid grey',
                  borderRight: '2px solid grey',
              }}
            >
              <TextField
                sx={{
                  textarea: {
                      color: "black"
                  },
                  "& fieldset": { border: 'none' },
                  '& ::placeholder': {
                    color: 'grey',
                    fontStyle: 'italic',
                    fontWeight: 'normal'
                  }
                }}
                placeholder='Première règle'
                fullWidth
                multiline
                value={row.first_rule}
                size='small'
                onChange={(e) => {
                    handleChangeTcsInformations("first_rule", e.target.value, indexRules)
                }}
                onBlur={() => {
                    sendVignetteRulesChangeToDb(indexRules)
                }}
              />
            </Grid>
            <Grid item sm={4}
              sx={{
                borderBottom: '2px solid grey',
                borderRight: '2px solid grey',
              }}
            >
              <TextField
                placeholder='Seconde règle'
                sx={{
                  textarea: {
                      color: "black"
                  },
                  "& fieldset": { border: 'none' },
                  '& ::placeholder': {
                    color: 'grey',
                    fontStyle: 'italic',
                    fontWeight: 'normal'
                  }
                }}
                fullWidth
                multiline
                value={row.second_rule}
                size='small'
                onChange={(e) => {
                    handleChangeTcsInformations("second_rule", e.target.value, indexRules)
                }}
                onBlur={() => {
                    sendVignetteRulesChangeToDb(indexRules)
                }}
              />
            </Grid>
            <Grid item sm={3.6}
              sx={{
                borderBottom: '2px solid grey',
                borderRight: '2px solid grey',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 0.3
              }}
            >
              {[-2, -1, 0, 1, 2].map((value) => (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={
                    <Radio
                      checked={row.good_answer === value}
                      onChange={() => {
                          handleChangeTcsInformations("good_answer", value, indexRules);
                          sendVignetteRulesChangeToDb(indexRules)
                      }}
                      color="primary"
                      sx={{
                          borderRadius: '50%',
                          '&:hover': {
                              bgcolor: 'transparent'
                          }
                      }}
                      type='radio'
                    />
                  }
                  label={value}
                  labelPlacement="bottom"
                  sx={{ flex: 1, margin: '0 4px' }}
                />
              ))}
            </Grid>
            <Grid item sm={0.4}
              sx={{
                  display: 'flex',
                  alignItems: 'center',
                  borderRight: '2px solid grey',
                  borderBottom: '2px solid grey',
                  backgroundColor: 'firebrick'
              }}
            >
              <IconButton
                sx={{
                  color: 'black'
                }}
                onClick={() => handleDeleteRule(indexRules)}
              >
                <DeleteOutline/>
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}